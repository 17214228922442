import { createFetchSlice, getInitialState } from '../sliceCreators/fetchSliceCreator';
import { createSelector } from 'reselect';
import { BioForTNEUser, BioFull } from '../../types/entities';
import { biosUri } from '../../api';

const slice = createFetchSlice('bio', {}, getInitialState<BioForTNEUser | BioFull>());

export function loadBio(uuid: string, options = {}) {
  return slice.loadUnlessTokenSame({
    uri: `${biosUri}/${uuid}`,
    ...options,
  });
}

const bioReducer = slice.reducer;
const bioSelector = slice.selector;
const bioReset = slice.actions.reset;

export { bioSelector, bioReset };

export const bioDataSelector = createSelector(bioSelector, v => v.data);

export const bioSuccess = slice.actions.success;

export default bioReducer;
