import { createNextState, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { keyBy, sortBy } from 'lodash-es';
import api, { fieldOptionsUri } from '../../api';
import { channelUuid, quarterUuid, tickerUuid, yearUuid } from '../../values/apiFields';
import { createFetchSlice, getInitialState } from '../sliceCreators/fetchSliceCreator';
import { FieldOptionChange, FieldType, HasUuid } from '../../types/entities';

export type FieldOptionDefinition = HasUuid & {
  name: string;
  parentFieldOptionUuid: string | null;
  parentFieldUuid: string | null;
  changes?: FieldOptionChange[];
};

export type FieldDefinition = HasUuid & {
  name: string;
  type: FieldType;
  options: FieldOptionDefinition[];
};

type FieldOptions = FieldDefinition[];

const slice = createFetchSlice(
  'fields',
  {
    addFieldOption: (
      state,
      {
        payload: [fieldUuid, newFieldOptionUuid, name, parentFieldUuid, parentFieldOptionUuid],
      }: PayloadAction<[string, string, string, string, string]>
    ) => {
      const field = (state.data || []).find(({ uuid }) => uuid === fieldUuid);
      if (!field) {
        throw new Error(`Field with a UUID of ${fieldUuid} was not found.`);
      }
      field.options.push({
        uuid: newFieldOptionUuid,
        name,
        parentFieldUuid,
        parentFieldOptionUuid,
      });
    },
    removeFieldOption: (
      state,
      { payload: [fieldUuid, optionUuid] }: PayloadAction<[string, string]>
    ) => {
      const field = (state.data || []).find(({ uuid }) => uuid === fieldUuid);
      if (!field) {
        throw new Error(`Field with a UUID of ${fieldUuid} was not found.`);
      }
      const options = field.options;
      options.splice(
        options.findIndex(({ uuid }) => uuid === optionUuid),
        1
      );
    },
  },
  getInitialState<FieldOptions>()
);

/**
 * Loads the fields for tagging/filtering reports.
 *
 * @return {Function}
 */
export function loadFields(options = {}) {
  return slice.loadUnlessTokenSame({
    uri: fieldOptionsUri,
    config: { params: { object_type: 'Reports' } },
    ...options,
  });
}

export async function createFieldOption(fieldUuid, name, parentFieldOptionUuid) {
  return (
    await api.post(fieldOptionsUri, {
      fieldUuid,
      value: name,
      externalParentFieldOptionUuid: parentFieldOptionUuid,
    })
  ).data.data;
}

const sortedFields = [channelUuid, tickerUuid];

const unsortedFieldsDataSelector = createSelector(slice.selector, fields => fields?.data || []);
const fieldsDataSelector = createSelector(unsortedFieldsDataSelector, fields =>
  createNextState(fields, fields => {
    for (const field of fields) {
      if (!sortedFields.includes(field.uuid)) {
        continue;
      }
      field.options = sortBy(field.options, v => v.name);
    }
  })
);

/**
 * Selector including a transform of the api response data shape so they could be used as-is
 * for 3rd party libraries (e.g. react-select).
 **/
export const fieldFormValuesSelector = createSelector(fieldsDataSelector, fields => ({
  ...fields,
  fields: fields.map(field => {
    return {
      ...field,
      // Override Report Title to Channel.
      ...(field.uuid === channelUuid ? { name: 'Channel' } : {}),
      // This is the shape of the options in the way react-select prefers it.
      options: field.options?.map(item => ({
        ...item,
        value: item.uuid,
        label: item.name,
      })),
    };
  }),
}));

export const selectFieldsByUuid = createSelector(fieldFormValuesSelector, ({ fields }) =>
  keyBy(fields, 'uuid')
);

export type UuidLabelMap = { [key: string]: string };

// Field and field option uuids mapped to their labels.
export const uuidLabelMapSelector = createSelector(fieldsDataSelector, data => {
  const ret: UuidLabelMap = {};
  for (let field of data) {
    ret[field.uuid] = field.name;
    for (let option of field.options || []) {
      ret[option.uuid] = option.name;
    }
  }
  return ret;
});

// Maps field option UUIDs to their field options.
export const fieldOptionUuidMapSelector = createSelector(fieldsDataSelector, data => {
  const ret: Record<string, FieldOptionDefinition> = {};
  for (let field of data) {
    for (let option of field.options || []) {
      ret[option.uuid] = option;
    }
  }
  return ret;
});

// Field UUIDs are the keys, values are objects with option labels as keys, and option UUIDs as values.
export const fieldLabelsToUuidsMapSelector = createSelector(fieldsDataSelector, data => {
  const ret: { [fieldUuid: string]: { [label: string]: string[] } } = {};
  for (const field of data) {
    const map: { [label: string]: string[] } = {};
    for (let option of field.options || []) {
      if (!map[option.name]) {
        map[option.name] = [];
      }
      map[option.name].push(option.uuid);
    }
    ret[field.uuid] = map;
  }
  return ret;
});

// Year-Quarter uuids pairs are the values, and they are sorted in chronologically ascending order.
export const yearQuartersIndexedSelector = createSelector(selectFieldsByUuid, (fieldsByUuid = {}) =>
  (fieldsByUuid[yearUuid]?.options || []).flatMap(year =>
    (fieldsByUuid[quarterUuid]?.options || []).map((quarter): [string, string] => [
      year.uuid,
      quarter.uuid,
    ])
  )
);

// Maps a year and quarter uuid strung together to their order index.
export const yearQuarterToIndexMapSelector = createSelector(
  yearQuartersIndexedSelector,
  yearQuarters => {
    const ret: { [yearQuarterUuids: string]: number } = {};
    for (let i = 0; i < yearQuarters.length; i++) {
      ret[yearQuarters[i][0] + yearQuarters[i][1]] = i;
    }
    return ret;
  }
);

// Selector for a callback that helps "increment" year-quarter pairs.
export const incrementYearQuarterSelector = createSelector(
  uuidLabelMapSelector,
  yearQuartersIndexedSelector,
  yearQuarterToIndexMapSelector,
  (uuidLabelMap, yearQuarters, yearQuarterToIndexMap) => (
    [uuidOfYear, uuidOfQuarter],
    decrement = false
  ) => {
    const orderIndex = yearQuarterToIndexMap[uuidOfYear + uuidOfQuarter];
    if (!orderIndex) {
      return null;
    }

    const increment = !decrement ? 1 : -1;

    return yearQuarters[orderIndex + increment] ?? null;
  }
);

const fieldsReducer = slice.reducer;

export const { addFieldOption, removeFieldOption } = slice.actions;

export default fieldsReducer;
