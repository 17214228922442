import React, { lazy, Suspense, useState } from 'react';
import { googleClientId } from '../../utils/env';
import { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { reportError } from '../../store/errorHandling';

const ReactGoogleLogin = lazy(() => import('react-google-login'));

type Props = {
  onSuccess: (response: GoogleLoginResponse | GoogleLoginResponseOffline) => void;
  onFailure: (error: any) => void;
};

export default function GoogleLogin(props: Props) {
  const [scriptLoadFailure, setScriptLoadFailure] = useState(false);

  if (!googleClientId) {
    return null;
  }

  if (scriptLoadFailure) {
    return <div />;
  }

  return (
    <Suspense fallback="Loading...">
      <ReactGoogleLogin
        clientId={googleClientId}
        buttonText="Login With Google"
        onSuccess={props.onSuccess}
        onFailure={props.onFailure}
        onScriptLoadFailure={failure => {
          setScriptLoadFailure(true);
          reportError(failure);
        }}
        prompt="consent"
      />
    </Suspense>
  );
}
