import { createFetchSlice, getInitialState } from '../sliceCreators/fetchSliceCreator';
import { createSelector } from 'reselect';
import { Attribute } from '../../types/entities';
import { getAttributeUrlForFieldOption } from './kpiReducer';

const slice = createFetchSlice('reportKpis', {}, getInitialState<Attribute[]>());

export function loadReportKpis(fieldOptions: string[], fields: string[]) {
  return slice.loadUnlessTokenSame({
    uri: getAttributeUrlForFieldOption(fieldOptions, fields),
  });
}

const reportKpisReducer = slice.reducer;
const reportKpisSelector = slice.selector;
const reportKpisReset = slice.actions.reset;
const reportKpisSuccess = slice.actions.success;

export { reportKpisSelector, reportKpisReset, reportKpisSuccess };

export const reportKpisDataSelector = createSelector(reportKpisSelector, v => v.data);

export default reportKpisReducer;
