import {
  ensureFieldOptionsValid,
  selectDynamicFieldsSelectedsByUuid,
} from './reducers/reportEditReducer';

export default function listeners(store) {
  let currentDynamicFields = null;

  // Listen for the report edit dynamic fields changing.
  // If they change, ensure the selected options are in the possible options list.
  store.subscribe(() => {
    let previousDynamicFields = currentDynamicFields;
    const state = store.getState();
    currentDynamicFields = selectDynamicFieldsSelectedsByUuid(state);

    if (currentDynamicFields !== previousDynamicFields) {
      ensureFieldOptionsValid(store.dispatch, state);
    }
  });
}
