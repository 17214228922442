/* eslint-disable react/prop-types */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { forwardRef } from 'react';
import FieldErrors from '../../FieldError/FieldErrors';
import FloatingPlaceholder from '../FakePlaceholder/FloatingPlaceholder';
import inputCss from './inputCss';

const StyledInput = styled.input(
  props => css`
    ${inputCss};
    ${props.disabled &&
      css`
        opacity: 0.75;
      `}
    ${props.forSidebar
      ? css`
          background-color: white;
          border-radius: 4px;
          border: 0;
          padding: 2px 8px;
          height: 38px;
          font-weight: normal;
        `
      : ''};
  `
);

const Input = (
  {
    type = 'text',
    error = null,
    value = undefined,
    forSidebar = false,
    noFloatingPlaceholder = false,
    ...restProps
  },
  ref
) => (
  <div>
    {!noFloatingPlaceholder && (
      <FloatingPlaceholder value={value} placeholder={restProps.placeholder} />
    )}

    <StyledInput type={type} forSidebar={forSidebar} ref={ref} value={value} {...restProps} />

    <FieldErrors errors={error} />
  </div>
);

const ForwardedRefInput = forwardRef(Input);

export default ForwardedRefInput;
