/** Standard padding for pages */

import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const paddingSize = '1rem';

export const pagePaddingCss = css`
  padding: ${paddingSize};
`;

const PagePadding = styled.div`
  ${pagePaddingCss}
`;

export default PagePadding;
