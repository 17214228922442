import { createSlice } from '@reduxjs/toolkit';
import { stringify } from 'qs';
import api from '../../api';
import { normalizeError } from '../errorHandling';
import { adminGroupsSelector } from './adminGroupsReducer';

const initialState = {
  selectedUuid: null,
  waiting: true,
  error: null,
  data: [],
};

async function fetchUsers(groupUuid) {
  return (
    await api.get('/v1/users', {
      params: { filter: { group: [groupUuid] } },
      paramsSerializer: params => stringify(params, { arrayFormat: 'brackets' }),
    })
  ).data.data;
}

export function loadAdminUsers(options = {}) {
  return async (dispatch, getState) => {
    const state = getState();
    if (options.reset) {
      dispatch(reset());
    }
    const adminGroups = adminGroupsSelector(state);
    if (!adminGroups.data.length) {
      // If there are no groups, there are no users.
      dispatch(usersSuccess([]));
      return;
    }
    if (!adminGroups.selectedUuid) {
      throw new Error('No group selected.');
    }

    dispatch(usersFetching());
    try {
      const users = await fetchUsers(adminGroups.selectedUuid);
      dispatch(usersSuccess(users));
    } catch (e) {
      dispatch(usersFail(normalizeError(e)));
      throw e;
    }
  };
}

const name = 'adminUsers';

export const adminUsersSelector = state => state[name];

const adminUsersReducer = createSlice({
  name,
  initialState,
  reducers: {
    usersFetching: state => ({ ...state, error: null, waiting: true }),
    usersSuccess: (state, { payload }) => ({ ...state, data: payload, waiting: false }),
    usersFail: (state, { payload }) => ({ ...state, error: payload, waiting: false }),
    usersSelectUuid: (state, { payload }) => ({ ...state, selectedUuid: payload }),
    reset: () => initialState,
    adminUserDelete: (state, { payload }) => {
      state.data.splice(
        state.data.findIndex(({ uuid }) => uuid === payload),
        1
      );
    },
  },
});

const {
  usersFetching,
  usersSuccess,
  usersFail,
  usersSelectUuid,
  reset,
  adminUserDelete,
} = adminUsersReducer.actions;

export { usersSelectUuid, adminUserDelete };

export default adminUsersReducer.reducer;
