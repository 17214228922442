import { readershipStatisticsUri } from '../../api';
import { createFetchSlice } from '../sliceCreators/fetchSliceCreator';

const slice = createFetchSlice('analytics');

/**
 * Loads the reports for tagging/filtering reports.
 *
 * @return {Function}
 */
export function loadAnalytics(search) {
  return slice.load({ uri: `${readershipStatisticsUri}${search}` });
}

export const selectAnalyticsState = slice.selector;

const { reset: analyticsReset } = slice.actions;

export { analyticsReset };

const analyticsReducer = slice.reducer;

export default analyticsReducer;
