import { createSelector } from '@reduxjs/toolkit';
import { accountsUri } from '../../api';
import { createFetchSlice } from '../sliceCreators/fetchSliceCreator';

const slice = createFetchSlice('accounts', {
  accountDelete: (state, { payload }) => {
    state.data.splice(
      state.data.findIndex(({ uuid }) => uuid === payload),
      1
    );
  },
});

export function loadAccounts(options = {}) {
  return slice.loadUnlessTokenSame({ uri: accountsUri, ...options });
}

export const accountsSelector = slice.selector;

export const accountsDataSelector = createSelector(
  accountsSelector,
  accounts => accounts.data || []
);

// For use in react-select.
export const accountOptionsSelector = createSelector(accountsDataSelector, data =>
  data.map(({ uuid, name }) => ({ value: uuid, label: name }))
);

export const accountsUuidMapSelector = createSelector(accountsDataSelector, data =>
  Object.fromEntries(data.map(({ uuid, name }) => [uuid, name]))
);

export const { accountDelete } = slice.actions;
const accountsReducer = slice.reducer;

export default accountsReducer;
