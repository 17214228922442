import { stringify } from 'qs';
import { attributesUri } from '../../api';
import { createFetchSlice } from '../sliceCreators/fetchSliceCreator';

const name = 'kpi';

export function getAttributeUrlForFieldOption(fieldOption, fields = null) {
  return `${attributesUri}?${stringify({
    filter: {
      fieldOption: !Array.isArray(fieldOption) ? [fieldOption] : fieldOption,
      field: fields,
    },
  })}`;
}

const slice = createFetchSlice(name, {
  deleteKPI: (state, { payload }) => {
    state.data.splice(
      state.data.findIndex(({ uuid }) => uuid === payload),
      1
    );
  },
});

let lastChannelUuid;

export function loadKPI(channelUuid, revalidate) {
  if (!revalidate && channelUuid === lastChannelUuid) {
    return;
  }
  return slice.load({
    uri: getAttributeUrlForFieldOption(channelUuid),
  });
}

export const kpiSelector = slice.selector;
export const { deleteKPI, success: kpiSuccess } = slice.actions;
const kpiReducer = slice.reducer;

export default kpiReducer;
