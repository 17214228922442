import { mapValues } from 'lodash-es';

/**
 * Performs a map recursively on arrays/objects.
 * The input can even be a primitive with the mapper callback simply called on it.
 *
 * @param subject
 * @param mapper
 * @returns {{}|*}
 */
export default function recursiveMap(subject, mapper) {
  if (Array.isArray(subject)) {
    return subject.map(value => recursiveMap(value, mapper));
  }
  if (subject instanceof Object) {
    return mapValues(subject, value => recursiveMap(value, mapper));
  }
  return mapper(subject);
}
