import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { loadingChunkFailedRegexp, normalizeError, UNAUTHENTICATED } from './store/errorHandling';
import { logout } from './store/reducers/authReducer';

class AppBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { eventId: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, normalizedError: normalizeError(error) };
  }

  componentDidCatch(error, errorInfo) {
    //eslint-disable-next-line no-console
    console.error({ error, errorInfo });
    const normalizedError = this.state.normalizedError;
    const message = normalizedError.message;

    if (error?.message && loadingChunkFailedRegexp.test(error.message)) {
      // The user probably had a tab open before a deploy, and the code-splitting encountered an outdated chunk
      // filename hash. So just reload the page, and it should be fine. Solution #3 from:
      // https://mitchgavan.com/code-splitting-react-safely/
      console.warn('reloading the page');

      window.location.reload();
      return;
    }

    if (normalizedError.type === UNAUTHENTICATED) {
      this.props.dispatch(logout());
      //eslint-disable-next-line no-console
      console.warn('Token not valid anymore. Refreshing the page.');
      window.location.reload();
      return;
    }

    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId, message });
    });
  }

  render() {
    if (this.state.hasError) {
      if (this.state.normalizedError.type === UNAUTHENTICATED) {
        return <div>Redirecting to the login page...</div>;
      }

      //render fallback UI
      return (
        <div>
          <div>{this.state.message}</div>
          <button onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>
            Report feedback
          </button>
        </div>
      );
    }

    //when there's not an error, render children untouched
    return this.props.children;
  }
}

AppBoundary.propTypes = {
  children: PropTypes.node,
  dispatch: PropTypes.func,
  normalizedError: PropTypes.object,
};

export default connect()(withRouter(AppBoundary));
