import { css } from '@emotion/react';
import styled from '@emotion/styled';

type Props = {
  stretch?: boolean;
  column?: boolean;
  fullHeight?: boolean;
};

export const flexCss = (props: Props) => css`
  display: flex;
  align-items: ${props.stretch ? 'stretch' : 'center'};
  flex-direction: ${props.column ? 'column' : 'row'};
  justify-content: center;
  ${props.fullHeight ? 'min-height: 100%;' : ''}
`;

const Flex = styled.div`
  ${(props: Props) => flexCss(props)}
`;

export default Flex;
