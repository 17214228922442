import * as Sentry from '@sentry/browser';
import { normalizeError, NOT_FOUND } from '../store/errorHandling';
import { isProduction } from './env';

export function reportException(error) {
  if (!isProduction) {
    //eslint-disable-next-line no-console
    console.error(error);
  }
  if (normalizeError(error).type === NOT_FOUND) {
    // Do not report these errors.
    return;
  }
  Sentry.captureException(error);
}
