import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import PropTypes, { bool, node } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { boxShadowRule, darkGray, gray, lightBlue, red } from '../../../styles/variables';
import Spinner from '../Spinner';

const backgroundColorMap = {
  lightBlue,
  blue: ({ theme }) => theme.primary,
  red,
  gray: darkGray,
  lightGray: gray,
};

const colorMap = {
  lightBlue: 'white',
  blue: 'white',
  red: 'white',
  gray: 'white',
  lightGray: 'white',
};

const borderColorMap = {
  red: '#e12125',
  default: '#eaeaea',
};

function getTextColor(buttonColor) {
  return colorMap[buttonColor] || '#3e4c56';
}

export const buttonCss = props => css`
  height: ${props.small ? '1.5' : '2.5'}rem;
  min-width: ${props.small ? 0 : '11rem'};
  ${props.fullWidth && 'width: 100%;'};
  background-color: ${(typeof backgroundColorMap[props.color] === 'function'
    ? backgroundColorMap[props.color](props)
    : backgroundColorMap[props.color]) || 'white'};
  border-color: ${borderColorMap[props.color || 'default'] || 'transparent'};
  border-style: solid;
  border-width: ${borderColorMap[props.color || 'default'] ? '1px' : 0};
  padding: 0.375rem 0.75rem; // This is how Bootstrap vertically centers link-buttons.
  border-radius: ${props.pill ? '1.25rem' : '3px'};
  color: ${getTextColor(props.color)};
  cursor: pointer;
  text-align: center;
  font-size: 1em;
  ${props.shadow && boxShadowRule};

  &[disabled] {
    cursor: default;
    opacity: 0.5;
  }
`;

const StyledButton = styled.button`
  ${buttonCss}
`;

const buttonAnchorCss = props => css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 150%;
  ${buttonCss(props)};
`;

function Button({ children, waiting = false, ...restProps }) {
  const buttonProps = { ...restProps };
  if (waiting) {
    // Disable the button if waiting.
    buttonProps.disabled = true;
    delete buttonProps.onClick;
  }
  return (
    <StyledButton {...buttonProps}>
      {waiting ? (
        <Spinner size={1.5} sizeUnit="em" color={getTextColor(restProps.color)} />
      ) : (
        children
      )}
    </StyledButton>
  );
}

Button.propTypes = {
  children: node,
  waiting: bool,
};

/** Link that's styled as a button */
export const ButtonLink = styled(Link, {
  shouldForwardProp(propName) {
    // DOM attributes and Link-specific attributes should be forwarded to <Link>.
    return isPropValid(propName) || ['component', 'to', 'replace', 'innerRef'].includes(propName);
  },
})(buttonAnchorCss);
export const ButtonAnchor = styled.a(buttonAnchorCss);

export default Button;

Button.propTypes = {
  color: PropTypes.oneOf(['blue', 'lightBlue', 'red', 'gray', 'lightGray', 'default']),
  small: PropTypes.bool,
  pill: PropTypes.bool,
  fullWidth: PropTypes.bool,
  shadow: PropTypes.bool,
};
