import api, { biosUri, PaginatedResponseEnvelope } from '../../api';
import { createFetchSlice, getInitialState } from '../sliceCreators/fetchSliceCreator';
import { createSelector } from 'reselect';
import { BioListItem } from '../../types/entities';
import { RootState } from '../index';
import { stringify } from 'qs';

const slice = createFetchSlice('bios', {}, getInitialState<BioListItem[]>());

export function loadBios(options: { searchString?: string } = {}) {
  return slice.loadUnlessTokenSame({
    uri:
      biosUri +
      stringify(options.searchString ? { filter: { companyName: options.searchString } } : {}, {
        addQueryPrefix: true,
      }),
    ...options,
  });
}

export function loadNextBioPage() {
  return async (dispatch, getState: () => RootState) => {
    const state = getState();
    const nextUrl = `${biosUri}?page=${(state.bios.meta?.current_page ?? 1) + 1}`;
    dispatch(slice.actions.waiting());
    try {
      const responseData = (await api.get<PaginatedResponseEnvelope<BioListItem[]>>(nextUrl)).data;
      // Append reports to the reports we had loaded already.
      const concattedData = (getState().bios.data ?? []).concat(responseData.data);
      dispatch(slice.actions.successOuter({ data: concattedData, meta: responseData.meta }));
    } catch (error) {
      dispatch(slice.actions.fail(error));
      throw error;
    }
  };
}

const biosReducer = slice.reducer;
const biosSelector = slice.selector;
const biosReset = slice.actions.reset;

export { biosSelector, biosReset };

export const biosDataSelector = createSelector(biosSelector, v => v.data);

export default biosReducer;
