import { ThemeProvider } from '@emotion/react';
import React, { useRef } from 'react';
import { Route, Switch, useHistory } from 'react-router';
import ForgotPasswordPage from './components/ForgotPasswordPage/ForgotPasswordPage';
import Layout from './components/Layout/Layout';
import Login from './components/Login/Login';
import PasswordResetPage from './components/PasswordResetPage/PasswordResetPage';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import channelDynamicsTheme from './values/themes/channelDynamicsTheme';
import theNetworkEffectTheme from './values/themes/theNetworkEffectTheme';
import { useSelector } from 'react-redux';
import { isRoleTne } from './utils/acl';

const tnePathRegex = /^\/tne\b/;

function App() {
  const { currentUser } = useSelector(state => ({
    currentUser: state.currentUser,
  }));
  const history = useHistory();
  const pathname = history.location.pathname;

  const currentUserRole = currentUser.data?.role;
  const userRoleIsTne = isRoleTne(currentUserRole);
  const routeIsTnePrefixed = tnePathRegex.test(pathname);

  // We need to keep track of if the user was TNE with a ref, otherwise on logout this information
  // would get lost before PrivateRoute's logic for determining whether to take you to the TNE
  // login page would get run.
  const lastUserWasTneRef = useRef(userRoleIsTne);
  if (currentUserRole) {
    lastUserWasTneRef.current = userRoleIsTne;
  }

  const showTneTheme = currentUserRole ? userRoleIsTne : routeIsTnePrefixed;

  const theme = showTneTheme ? theNetworkEffectTheme : channelDynamicsTheme;

  return (
    <ThemeProvider theme={theme}>
      <Switch>
        <Route path="/tne/login" exact component={Login} />
        <Route path="/login" exact component={Login} />
        <Route path="/password-reset" exact component={PasswordResetPage} />
        <Route path="/forgot-password" exact component={ForgotPasswordPage} />
        <PrivateRoute path="/" component={Layout} isTneUser={lastUserWasTneRef.current} />
      </Switch>
    </ThemeProvider>
  );
}

export default App;
