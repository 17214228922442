import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { CSSProperties } from 'react';
import { ClipLoader } from 'react-spinners';
import Flex from './Flex/Flex';

const absolutePositionedCss = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
`;

const Container = styled(Flex)(
  ({ absolutePositioned }: { absolutePositioned?: boolean }) =>
    css`
      ${absolutePositioned ? absolutePositionedCss : ''};
    `
);

type Props = {
  small?: boolean;
  color?: string;
  absolutePositioned?: boolean;
  style?: CSSProperties;
  size?: any;
  [key: string]: any;
};

const Spinner = ({
  small,
  color,
  absolutePositioned,
  style = {},
  size,
  'data-testid': dataTestId,
  ...restProps
}: Props) => {
  const { minHeight, ...restStyle } = style;
  const loaderProps = { ...restStyle, ...restProps };
  const containerStyle = { minHeight };

  return (
    <Container
      style={containerStyle}
      absolutePositioned={absolutePositioned}
      data-testid={dataTestId}
    >
      <ClipLoader size={small ? 15 : size} color={color} {...loaderProps} />
    </Container>
  );
};

export default Spinner;
