import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../../api';
import { PATH_LOGIN } from '../../router/paths';
import { normalizeError, NOT_FOUND, VALIDATION_FAILURE } from '../../store/errorHandling';
import { red } from '../../styles/variables';
import { tenantUuid } from '../../values/apiFields';
import FieldErrors from '../FieldError/FieldErrors';
import LoggedOutLayout, {
  FailureContainer,
  FormInputsContainer,
  Input,
  InputContainer,
  LinksContainer,
  LoginButton,
} from '../LoggedOutLayout/LoggedOutLayout';
import Spinner from '../UI/Spinner';

function ForgotPasswordPage() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  async function onSubmit(event) {
    event.preventDefault();
    setError('');
    setLoading(true);
    setSuccessMessage('');
    try {
      const message = (
        await api.post(
          '/v1/forgot_password',
          {
            tenant: tenantUuid,
            email,
          },
          {
            params: {
              returnUrl: `${window.location.origin}/password-reset`,
            },
          }
        )
      ).data.message;
      setSuccessMessage(message);
    } catch (e) {
      const normalizedError = normalizeError(e);
      setError(normalizedError);
    } finally {
      setLoading(false);
    }
  }

  let errorMessage = '';
  if (error) {
    errorMessage =
      error.type === NOT_FOUND
        ? 'We could not find a user with this email address.'
        : error.message;
  }

  const fieldErrors = error?.type === VALIDATION_FAILURE ? error.data : {};

  return (
    <LoggedOutLayout onSubmit={onSubmit}>
      <FormInputsContainer>
        <h1 style={{ fontSize: '1rem', marginBottom: '1rem', fontWeight: 'bold' }}>
          Forgot Password
        </h1>
        {errorMessage && (
          <FailureContainer>
            <p style={{ color: red }}>{errorMessage}</p>
          </FailureContainer>
        )}
        {successMessage && <p style={{ color: '#4BB543' }}>{successMessage}</p>}
        <InputContainer>
          <Input
            type="text"
            name="email"
            placeholder="Email"
            data-testid="forgot-password-email"
            required
            value={email}
            onChange={evt => setEmail(evt.target.value)}
          />
        </InputContainer>
        <FieldErrors errors={fieldErrors.email} />
        <div style={{ flex: 1 }} />
        <LinksContainer>
          <div style={{ flex: 1 }} />
          <Link to={PATH_LOGIN}>Back to login</Link>
        </LinksContainer>
      </FormInputsContainer>
      <LoginButton
        small
        color="blue"
        type="submit"
        data-testid="login-submit-button"
        disabled={loading}
      >
        {loading ? <Spinner small color="white" /> : 'Send reset email'}
      </LoginButton>
    </LoggedOutLayout>
  );
}

export default ForgotPasswordPage;
