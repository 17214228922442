import { UserWithPermissions } from '../types/entities';
import { ROLE_TENANT_OWNER, ROLE_TNE_ADMIN } from '../values/roles';

/**
 * Checks if the given role is for a TNE user or admin.
 */
export function isRoleTne(role?: string) {
  return role?.startsWith('tne_');
}

export function canUserEditBio(user?: UserWithPermissions, bio?: { email: string | null } | null) {
  return (
    !bio ||
    [ROLE_TENANT_OWNER, ROLE_TNE_ADMIN].includes(user?.role ?? '') ||
    (user && user.email === bio?.email)
  );
}

export function canUserEditCompliance(user?: UserWithPermissions) {
  return canUserSeeOtherBios(user);
}

export function canUserSeeCompliance(user?: UserWithPermissions) {
  return canUserSeeOtherBios(user);
}

export function canUserSeeOtherBios(user?: UserWithPermissions) {
  return [ROLE_TENANT_OWNER, ROLE_TNE_ADMIN].includes(user?.role ?? '');
}
