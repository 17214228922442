import { keyBy } from 'lodash-es';
import { createSelector } from '@reduxjs/toolkit';
import { reportsUri } from '../../api';
import { industryUuid, reportTypeUuid } from '../../values/apiFields';
import { createFetchSlice } from '../sliceCreators/fetchSliceCreator';

const slice = createFetchSlice('reportsStatistics');

/**
 * Loads the reports for tagging/filtering reports.
 *
 * @return {Function}
 */
export function loadReportsStatistics() {
  return slice.load({
    uri: `${reportsUri}/statistics`,
    config: {
      params: { fields: [reportTypeUuid, industryUuid] },
    },
  });
}

export const selectReportsStatisticsStateDataByUuid = createSelector(
  slice.selector,
  reducerState => ({ ...reducerState, data: keyBy(reducerState.data, 'uuid') })
);

const reportsStatisticsReducer = slice.reducer;

export default reportsStatisticsReducer;
