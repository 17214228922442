import React from 'react';
import { ReactComponent as SmallLogoComponent } from '../../assets/small-logo.svg';
import { ReactComponent as BigLogoComponent } from '../../assets/big-logo.svg';
import styled from '@emotion/styled';

const SmallLogo = styled(SmallLogoComponent)`
  height: 90%;
  width: 3rem;
  margin-right: 0.5rem;
  fill: white;
`;

type ThemeName = 'cd' | 'tne';

const channelDynamicsTheme = {
  name: 'cd' as ThemeName,
  topBackground: '#262268',
  sideBackground: '#b5b6b9',
  navBackground: '#c8cbcf',
  primary: '#262268',
  loggedInTopLeftLogo: (
    <>
      <SmallLogo />
      <span style={{ fontWeight: 'bold', fontSize: '1rem' }}>Channel Dynamics</span>
    </>
  ),
  BigLogoComponent,
  SmallLogoComponent,

  loginBackground: 'transparent',
  loginTextColor: 'inherit',
} as any;

export default channelDynamicsTheme;
