import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import api from '../../api';
import { normalizeError } from '../errorHandling';
import { authLogout } from './authReducer';

const waiting = createAction('me/waiting');
const success = createAction('me/success');
const fail = createAction('me/fail');

async function fetchMe() {
  const response = await api.get('/v1/me');
  return response.data.data;
}

async function fetchPermissions() {
  const response = await api.get('/v1/me/permissions');
  return response.data.data;
}

export function loadCurrentUser() {
  return async dispatch => {
    dispatch(waiting());
    try {
      const [meData, permissionsData] = await Promise.all([fetchMe(), fetchPermissions()]);
      // Add the permissions to the "me" data.
      meData.permissions = permissionsData;
      dispatch(success(meData));
    } catch (error) {
      dispatch(fail(normalizeError(error)));
      throw error;
    }
  };
}

const initialState = {
  waiting: true,
  error: null,
  data: {},
};

export const selectCurrentUserData = state => state.currentUser.data;

export const selectPermissions = createSelector(
  state => state.currentUser,
  currentUser => currentUser.data.permissions || []
);

const currentUserReducer = createReducer(initialState, {
  [waiting]: state => ({ ...state, error: null, waiting: true }),
  [success]: (state, { payload }) => ({ ...state, data: payload, waiting: false }),
  [fail]: (state, { payload }) => ({ ...state, error: payload, waiting: false }),
  [authLogout]: () => initialState,
});

export const canEditReports = createSelector(
  state => state,
  state => state.data.includes('field_options-create')
);

export default currentUserReducer;
