import { trimStart } from 'lodash-es';
import { parse } from 'qs';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import api from '../../api';
import { PATH_FORGOT_PASSWORD, PATH_LOGIN } from '../../router/paths';
import { normalizeError, VALIDATION_FAILURE } from '../../store/errorHandling';
import { red } from '../../styles/variables';
import { tenantUuid } from '../../values/apiFields';
import FieldErrors from '../FieldError/FieldErrors';
import LoggedOutLayout, {
  FailureContainer,
  FormInputsContainer,
  Input,
  InputContainer,
  LinksContainer,
  LoginButton,
} from '../LoggedOutLayout/LoggedOutLayout';
import Spinner from '../UI/Spinner';

function PasswordResetPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const history = useHistory();
  const location = history.location;
  // Password reset token from the email.
  const token = parse(trimStart(location.search, '?')).token;

  async function onSubmit(event) {
    event.preventDefault();
    setError(null);
    setLoading(true);
    setSuccessMessage('');
    try {
      const message = (
        await api.post('/v1/reset_password', {
          tenant: tenantUuid,
          token: token,
          email,
          password,
          password_confirmation: passwordConfirmation,
        })
      ).data.message;
      setSuccessMessage(message);
    } catch (e) {
      setError(normalizeError(e));
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!token) {
      // Token is missing that should have been in the email. Go back to the Forgot Password page.
      history.push({ pathname: PATH_FORGOT_PASSWORD });
    }
  }, [history, token]);

  const fieldErrors = error?.type === VALIDATION_FAILURE ? error.data : {};

  return (
    <LoggedOutLayout onSubmit={onSubmit}>
      <FormInputsContainer>
        <h1 style={{ fontSize: '1rem', marginBottom: '1rem', fontWeight: 'bold' }}>
          Reset Password
        </h1>
        {error && (
          <FailureContainer>
            <p style={{ color: red }}>{error.message}</p>
          </FailureContainer>
        )}
        {successMessage && <p style={{ color: '#4BB543' }}>{successMessage}</p>}
        {!successMessage && (
          <div>
            <InputContainer>
              <Input
                type="text"
                name="email"
                placeholder="Email"
                data-testid="password-reset-email"
                required
                value={email}
                onChange={evt => setEmail(evt.target.value)}
              />
            </InputContainer>
            <FieldErrors errors={fieldErrors.email} />
            <InputContainer>
              <Input
                type="password"
                name="password"
                placeholder="Password"
                data-testid="reset-password-password"
                required
                value={password}
                onChange={evt => setPassword(evt.target.value)}
              />
            </InputContainer>
            <FieldErrors errors={fieldErrors.password} />
            <InputContainer>
              <Input
                type="password"
                name="password_confirmation"
                placeholder="Password Confirmation"
                data-testid="reset-password-password-confirmation"
                required
                value={passwordConfirmation}
                onChange={evt => setPasswordConfirmation(evt.target.value)}
              />
            </InputContainer>
            <FieldErrors errors={fieldErrors.password_confirmation} />
          </div>
        )}
        <div style={{ flex: 1 }} />
        <LinksContainer>
          {error && <Link to={PATH_FORGOT_PASSWORD}>Back to Forgot Password</Link>}
          <div style={{ flex: 1 }} />
          <Link to={PATH_LOGIN}>Back to login</Link>
        </LinksContainer>
      </FormInputsContainer>
      {!successMessage && (
        <LoginButton
          small
          color="blue"
          type="submit"
          data-testid="login-submit-button"
          disabled={loading}
        >
          {loading ? <Spinner small color="white" /> : 'Reset password'}
        </LoginButton>
      )}
    </LoggedOutLayout>
  );
}

export default PasswordResetPage;
