import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { useAppDispatch } from '../../store';
import { ErrorBoundary, normalizeError, UNAUTHENTICATED } from '../../store/errorHandling';
import { logout } from '../../store/reducers/authReducer';

const PrivateRoute = ({ component: Component, isTneUser, ...rest }) => {
  const dispatch = useAppDispatch();

  const onError = useCallback(
    error => {
      // Handle Unauthenticated (bad token).
      if (normalizeError(error).type === UNAUTHENTICATED) {
        //eslint-disable-next-line no-console
        console.warn('Failed to authenticate. Redirecting to login.');

        dispatch(logout());
        return <div>Logging you out...</div>;
      }

      // Can't handle this error. Re-throw it.
      throw error;
    },
    [dispatch]
  );

  const redirectPath = isTneUser ? '/tne/login' : '/login';

  return (
    <Route
      {...rest}
      render={props =>
        rest.isAuthenticated ? (
          <ErrorBoundary onError={onError}>
            <Component {...props} />
          </ErrorBoundary>
        ) : (
          <Redirect to={{ pathname: redirectPath, state: { from: props.location } }} />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType,
  location: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  isTneUser: PropTypes.bool,
};

const mapStateToProps = state => {
  return {
    isAuthenticated: !!state.auth.token,
  };
};

export default connect(mapStateToProps)(PrivateRoute);
