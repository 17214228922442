import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import api, { PaginatedResponseEnvelope, reportsUri } from '../../api';
import { reportDeleteSuccess } from './reportDeleteReducer';
import { ReportListItem } from '../../types/entities';
import { RootState } from '../index';

const reportsWaiting = createAction('reports/waiting');
const reportsSuccess = createAction<[ReportListItem[], string | null]>('reports/success');
const reportsFail = createAction<any>('reports/fail');
export const reportsReset = createAction('reports/reset');

async function fetchReports(queryString = '') {
  return (await api.get<PaginatedResponseEnvelope<ReportListItem[]>>(`${reportsUri}${queryString}`))
    .data;
}

/**
 * Loads the reports for tagging/filtering reports.
 *
 * @return {Function}
 */
export function loadReports(queryString) {
  return async dispatch => {
    dispatch(reportsReset());
    dispatch(reportsWaiting());
    try {
      const responseData = await fetchReports(queryString);
      dispatch(reportsSuccess([responseData.data, responseData.links.next]));
    } catch (error) {
      dispatch(reportsFail(error));
      throw error;
    }
  };
}

export function loadNextReportsPage() {
  return async (dispatch, getState) => {
    const state = getState();
    const nextUrl = getNextUrl(state);
    if (!nextUrl) {
      // There is no next page.
      return;
    }
    dispatch(reportsWaiting());
    try {
      const responseData = (await api.get<PaginatedResponseEnvelope<ReportListItem[]>>(nextUrl))
        .data;
      // Append reports to the reports we had loaded already.
      const concattedData = (selectReportsState(getState()).data || []).concat(responseData.data);
      dispatch(reportsSuccess([concattedData, responseData.links.next]));
    } catch (error) {
      dispatch(reportsFail(error));
      throw error;
    }
  };
}

const initialState = {
  waiting: true,
  error: null,
  data: null as ReportListItem[] | null,
  nextPageUrl: null as null | string,
};

export const selectReportsState = (state: RootState): typeof initialState => state.reports;

const getNextUrl = createSelector(selectReportsState, reports => reports.nextPageUrl);

const reportsReducer = createReducer(initialState, {
  [reportsWaiting.type]: state => ({ ...state, error: null, waiting: true }),
  [reportsSuccess.type]: (state, { payload: [data, nextPageUrl] }) => ({
    ...state,
    data,
    waiting: false,
    nextPageUrl,
  }),
  [reportsFail.type]: (state, { payload }) => ({
    ...state,
    error: payload,
    waiting: false,
    nextPageUrl: null,
  }),
  [reportDeleteSuccess.type]: (state, { payload: uuid }) => {
    if (!state.data) {
      return;
    }
    // Remove the deleted uuid from the list.
    state.data.splice(
      state.data.findIndex(item => item.uuid === uuid),
      1
    );
  },
  [reportsReset.type]: () => initialState,
});

export default reportsReducer;
