import { companiesUri } from '../../api';
import { createFetchSlice, getInitialState } from '../sliceCreators/fetchSliceCreator';
import { createSelector } from 'reselect';
import { Company } from '../../types/entities';

const slice = createFetchSlice('companies', {}, getInitialState<Company[]>());

export function loadCompanies(options = {}) {
  return slice.loadUnlessTokenSame({
    uri: `${companiesUri}`,
    ...options,
  });
}

const companiesReducer = slice.reducer;
const companiesSelector = slice.selector;
const companiesReset = slice.actions.reset;

export { companiesSelector, companiesReset };

export const companiesDataSelector = createSelector(companiesSelector, v => v.data);

export default companiesReducer;
