import { reportsUri } from '../../api';
import { createFetchSlice, getInitialState } from '../sliceCreators/fetchSliceCreator';
import { EARNINGS_PROPERTY } from '../sliceCreators/adminEditSliceCreator';
import { RootState } from '../index';
import { ApiCallNote } from '../../utils/hooks/noteHooks';
import { NoteByCompany, ReportListItem } from '../../types/entities';
import { createSelector } from 'reselect';

const slice = createFetchSlice('reportShow', {}, getInitialState<ShowDataType>());

export const showReportSuccess = slice.actions.success;
export const showReportReset = slice.actions.reset;

type NumericScore = 1 | 2 | 3 | 4 | 5;

export type ReportAttribute = {
  attributeOptionUuid: string;
  attributeUuid: string;
  fieldOptionUuid?: string;
};

export type ShowDataType = ReportListItem & {
  [EARNINGS_PROPERTY]: Array<{
    correctness: NumericScore | null;
    date: string | null;
    kpiUuid: string;
    tickerUuid: string;
    value: NumericScore | null;
  }>;
  deeperDive?: ApiCallNote[];
  companyNotes: NoteByCompany[];
};

/**
 * Loads the showReport for tagging/filtering showReport.
 *
 * @return {Function}
 */
export function showReport(uuid) {
  return async (dispatch, getState: () => RootState) => {
    if (reportShowDataSelector(getState())?.uuid === uuid) {
      // Already loaded.
      return;
    }
    return dispatch(slice.load({ uri: `${reportsUri}/${uuid}` }));
  };
}

const reportShowReducer = slice.reducer;
export const reportShowSelector = slice.selector;
export const reportShowDataSelector = createSelector(reportShowSelector, s => s.data);

export default reportShowReducer;
