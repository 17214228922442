import { createFetchSlice } from '../sliceCreators/fetchSliceCreator';

const slice = createFetchSlice('controlPanelWidgets');

/**
 * Loads the reports for tagging/filtering reports.
 *
 * @return {Function}
 */
export function loadControlPanelWidgets() {
  return slice.load({ uri: `/v1/admin/statistics` });
}
export const controlPanelWidgetsSelector = slice.selector;

const controlPanelWidgetsReducer = slice.reducer;

export default controlPanelWidgetsReducer;
