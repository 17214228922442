/**
 * Abstraction over localStorage and sessionStorage.
 *
 * sessionStorage gets cleared when you close the browser; localStorage doesn't.
 */

/**
 * Saves a value to localStorage or sessionStorage
 *
 * @param key
 * @param value
 * @param remember if TRUE, saves to localStorage, otherwise saves to sessionStorage.
 */
export function saveToStorage(key, value, remember = false) {
  clearFromStorage(key);
  window[remember ? 'localStorage' : 'sessionStorage'][key] = value;
}

/**
 * Gets a value from localStorage or sessionStorage
 *
 * @param key
 */
export function getFromStorage(key) {
  const ret = window.localStorage.getItem(key);
  if (ret === null) {
    return window.sessionStorage.getItem(key);
  }
  return ret;
}

export function clearFromStorage(key) {
  window.localStorage.removeItem(key);
  window.sessionStorage.removeItem(key);
}
