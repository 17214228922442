/**
 * Last published report of the given channel.
 * This is for prefilling suggestions for ticker/year/quarter, About The Companies,
 * and Company Tagline (from company notes).
 *
 * The data can either be null (not loaded), and empty array (no previous report found), or a
 * single element array with the previous report.
 */

import { reportsUri } from '../../api';
import { createFetchSlice, getInitialState } from '../sliceCreators/fetchSliceCreator';
import { createSelector } from 'reselect';
import { ShowDataType } from './reportShowReducer';
import { stringify } from 'qs';
import { channelUuid } from '../../values/apiFields';

const slice = createFetchSlice(
  'previousChannelReport',
  {},
  getInitialState<[ShowDataType] | [] | null>()
);

export function loadPreviousChannelReport(_channelUuid: string, options = {}) {
  const params: any = {
    perPage: 1,
    published: 1,
    filter: {
      [channelUuid]: _channelUuid,
    },
  };

  const queryString = `?${stringify(params)}`;

  return slice.loadUnlessTokenSame({
    uri: `${reportsUri}${queryString}`,
    ...options,
  });
}

const previousChannelReportReducer = slice.reducer;
const previousChannelReportSelector = slice.selector;
const previousChannelReportReset = slice.actions.reset;
const previousChannelReportSuccess = slice.actions.success;

export { previousChannelReportSelector, previousChannelReportReset, previousChannelReportSuccess };

export const previousChannelReportDataSelector = createSelector(
  previousChannelReportSelector,
  v => v.data
);

export default previousChannelReportReducer;
