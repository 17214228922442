/**
 * For creating common GET request type slices (for reducers).
 */

import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
  ValidateSliceCaseReducers,
} from '@reduxjs/toolkit';
import api, { MetaPagination, ResponseEnvelope } from '../../api';
import { normalizeError } from '../errorHandling';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AppDispatch, RootState } from '../index';
import { pick } from 'lodash-es';

type LoadOptions = {
  uri: string;
  stealth?: boolean;
  config?: AxiosRequestConfig;
};

export interface GenericFetchState<T> {
  data: T | null;
  waiting: boolean;
  error: any;
  meta: null | MetaPagination;
}

export function getInitialState<T>() {
  return {
    waiting: true,
    error: null,
    data: null as T | null,
    meta: null as MetaPagination | null,
  };
}

/**
 * You can find the created reducer in the returned object's "reducer" property.
 *
 * @param name {string} The name of the reducer.
 * @param reducers {Object=} Reducers to create in addition to the default ones.
 * @param initialState Use this for typescript types.
 * @returns Object
 */
export function createFetchSlice<
  State extends GenericFetchState<unknown>,
  Reducers extends SliceCaseReducers<State>,
  T extends Exclude<State['data'], null> // The underlying type in the data property of the response.
>(
  name: string,
  reducers: ValidateSliceCaseReducers<State, Reducers>,
  initialState: State = getInitialState() as any
) {
  type Response = AxiosResponse<ResponseEnvelope<T>>;

  let token;

  // URI that was last successful.
  let lastLoadUri = '';
  let cachedResponse: Response | undefined;

  /**
   * This is the main exposed function; this is what needs to be dispatched to load the data.
   */
  function load(options: LoadOptions) {
    return async (dispatch: AppDispatch) => {
      const { stealth, uri, config } = options;

      if (!stealth) {
        dispatch((actions.waiting as any)());
      }
      try {
        const response = await api.get<ResponseEnvelope<T>>(uri, config);
        const fetchedData = response.data;
        dispatch((actions.successOuter as any)(fetchedData));
        lastLoadUri = uri; // Set the cache-key URI.
        cachedResponse = response; // Set the cached response.

        return response;
      } catch (error) {
        dispatch((actions.fail as any)(normalizeError(error)));
        throw error;
      }
    };
  }

  function loadUnlessTokenSame(options: LoadOptions & { force?: boolean }) {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
      // Do not load again if the auth token didn't change.
      const stateToken = getState().auth;
      const { force } = options;
      const data = selector(getState()).data;
      if (!force && lastLoadUri === options.uri && token === stateToken && data && cachedResponse) {
        return cachedResponse;
      }
      token = stateToken;

      return dispatch(load(options));
    };
  }

  const slice = createSlice({
    name,
    initialState,
    reducers: {
      waiting: state => ({ ...state, error: null, waiting: true }),
      success: (state, { payload }: PayloadAction<T>) => ({
        ...state,
        data: payload,
        waiting: false,
      }),
      successOuter: (state, { payload }: PayloadAction<Pick<State, 'data' | 'meta'>>) => ({
        ...state,
        error: null,
        waiting: false,
        ...pick(payload, ['data', 'meta']),
      }),
      fail: (state, { payload }) => ({
        ...state,
        error: payload,
        waiting: false,
      }),
      reset: () => initialState,
      ...reducers,
    },
  });

  const actions = slice.actions;

  const selector: (state: RootState) => State = state => state[name];

  return {
    ...slice,
    load,
    loadUnlessTokenSame,
    selector,
  };
}
