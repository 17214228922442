/**
 * Variables received from the backend, set in a script tag.
 **/
const env = import.meta.env;

const backendVars = window.backendVars || {
  sentryDsn: env.VITE_SENTRY_DSN,
  gitSha1: env.VITE_GIT_SHA1,
};

export default backendVars;
