import { createSelector, createSlice } from '@reduxjs/toolkit';
import { accountsDataSelector, accountsUuidMapSelector } from './accountsReducer';

const initialState = {
  selectedUuid: null,
};

export function ensureValidAdminAccountUuidSelected() {
  return (dispatch, getState) => {
    const state = getState();
    const selectedUuid = selectedAccountUuidSelector(state);
    if (accountsUuidMapSelector(state)[selectedUuid]) {
      // A valid one is already selected.
      return;
    }

    // Try to select the first account, or null.
    const uuid = (accountsDataSelector(state)[0] || {}).uuid || null;
    dispatch(selectAdminAccountUuid(uuid));
  };
}

const adminAccountsReducer = createSlice({
  name: 'adminAccounts',
  initialState,
  reducers: {
    selectAdminAccountUuid: (state, { payload }) => {
      state.selectedUuid = payload;
    },
  },
});

export const { selectAdminAccountUuid } = adminAccountsReducer.actions;

export const selectedAccountUuidSelector = createSelector(
  state => state.adminAccounts,
  adminAccounts => adminAccounts.selectedUuid
);

export default adminAccountsReducer.reducer;
