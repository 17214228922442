import { notesUri } from '../../api';
import {
  createFetchSlice,
  GenericFetchState,
  getInitialState,
} from '../sliceCreators/fetchSliceCreator';
import { createSelector } from 'reselect';
import { ApiCallNote } from '../../utils/hooks/noteHooks';
import { PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../index';

type State = GenericFetchState<ApiCallNote[]> & { lastModified?: string };

const slice = createFetchSlice(
  'companyNotes',
  {
    setLastModified(state, { payload }: PayloadAction<string | undefined>) {
      // Update the Last-Modified date for Optimistic Concurrency Control.
      if (payload) {
        state.lastModified = payload;
      } else {
        delete state.lastModified;
      }
    },
  },
  getInitialState() as State
);

export function loadCompanyNotes(companyUuid: string, reportUuid: string, options = {}) {
  return async (dispatch: AppDispatch) => {
    const response = await dispatch(
      slice.loadUnlessTokenSame({
        uri: `${notesUri}?companyUuid=${companyUuid}&entityUuid=${reportUuid}`,
        ...options,
      })
    );

    dispatch(slice.actions.setLastModified(response.headers['last-modified']));

    return response;
  };
}

const companyNotesReducer = slice.reducer;
const companyNotesSelector = slice.selector;
const companyNotesReset = slice.actions.reset;

export { companyNotesSelector, companyNotesReset };

export const companyNotesDataSelector = createSelector(companyNotesSelector, v => v.data);

export default companyNotesReducer;
