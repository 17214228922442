import { podcastsUri } from '../../api';
import { createFetchSlice, getInitialState } from '../sliceCreators/fetchSliceCreator';
import { createSelector } from 'reselect';
import { Podcast } from '../../types/entities';

const slice = createFetchSlice('podcasts', {}, getInitialState<Podcast>());

export function loadPodcasts() {
  return slice.loadUnlessTokenSame({
    uri: podcastsUri,
  });
}

const podcastsReducer = slice.reducer;
const podcastsSelector = slice.selector;
const podcastsReset = slice.actions.reset;

export { podcastsSelector, podcastsReset };

export const podcastsDataSelector = createSelector(podcastsSelector, v => v.data);

export default podcastsReducer;
