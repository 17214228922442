import { object } from 'prop-types';
import React from 'react';
import { normalizeError, NOT_FOUND } from '../../store/errorHandling';
import PagePadding from '../Layout/PagePadding/PagePadding';

function ErrorPage({ error }) {
  const normalizedError = normalizeError(error);
  const displayMessage =
    normalizedError.type === NOT_FOUND ? 'This page was not found.' : normalizedError.message;

  return <PagePadding>{displayMessage}</PagePadding>;
}

ErrorPage.propTypes = {
  error: object,
};

export default ErrorPage;
