import styled from '@emotion/styled';
import React from 'react';
import { darkerGray } from '../../../styles/variables';

const StyledPlaceholder = styled.div`
  position: absolute;
  width: 100%;
  font-size: 0.7em;
  color: ${darkerGray};
  pointer-events: none;
`;

/**
 * Alternative placeholder to show if the regular placeholder wouldn't show because there's a value.
 */
function FloatingPlaceholder({ value, placeholder }: Props) {
  const shouldShow = placeholder && value && (!Array.isArray(value) || value.length);

  return shouldShow ? (
    <div style={{ position: 'relative' }}>
      <StyledPlaceholder>{placeholder}</StyledPlaceholder>
    </div>
  ) : null;
}

type Props = {
  value: unknown;
  placeholder: React.ReactNode;
};

export default FloatingPlaceholder;
