import { callsUri } from '../../api';
import { createFetchSlice, getInitialState } from '../sliceCreators/fetchSliceCreator';
import { createSelector } from 'reselect';
import { Company } from '../../types/entities';

export type Call = {
  company: Company;
  visible: boolean;
};

const slice = createFetchSlice('reportCalls', {}, getInitialState<Call[]>());

export function loadReportCalls(reportUuid: string, options = {}) {
  return slice.loadUnlessTokenSame({
    uri: `${callsUri}?entityUuid=${reportUuid}`,
    ...options,
  });
}

const reportCallsReducer = slice.reducer;
const reportCallsSelector = slice.selector;
const reportCallsReset = slice.actions.reset;
const reportCallsSuccess = slice.actions.success;

export { reportCallsSelector, reportCallsReset, reportCallsSuccess };

export const reportCallsDataSelector = createSelector(reportCallsSelector, v => v.data);

export default reportCallsReducer;
