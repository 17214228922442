import { useCallback, useEffect, useState } from 'react';
import { parse, stringify } from 'qs';
import { PATH_REPORTS, pathsUsingFilters } from '../../router/paths';
import { useHistory } from 'react-router';
import { LocationDescriptor } from 'history';
import { loadFields } from '../../store/reducers/fieldsReducer';
import { useDispatch } from 'react-redux';

type Filters = {
  search?: string;
  [uuid: string]: any;
};

export function useReportFilters() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [filters, setFilters] = useState<Filters>({});

  useEffect(() => {
    // Load the fields for filtering.
    dispatch(loadFields());
    // On first page load, if there was search in the query, set the filters' state to match
    // what's in the query.
    const parsed: Filters = parse(
      history.location.search ? history.location.search.substring(1) : ''
    );
    setFilters((parsed && parsed.filter) || {});
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search]);

  const onChange = useCallback(
    (uuid, values) => {
      if (!values || values.length === 0) {
        const { [uuid]: deleted, ...remainingFilters } = filters;
        // Clear item from filter.
        setFilters(remainingFilters);
      } else {
        // Set item to filter.
        setFilters({ ...filters, [uuid]: values });
      }
    },
    [filters]
  );

  const applyFilters = useCallback(
    evt => {
      evt.preventDefault();
      const isElasticSearch = evt.currentTarget.name === 'elasticsearch';
      const { search: searchFilter, ...restFilters } = filters;
      const search = stringify(
        { filter: isElasticSearch ? { search: searchFilter } : restFilters },
        { addQueryPrefix: true }
      );

      const historyEntry: LocationDescriptor = { search };
      if (!pathsUsingFilters.includes(history.location.pathname)) {
        // If we're not on a path that supports using filters, go to the reports page by default.
        historyEntry.pathname = PATH_REPORTS;
      }
      if (history.location.search === historyEntry.search && !historyEntry.pathname) {
        // Replace history instead if the query is the same and we're not going to a different page.
        history.replace(historyEntry);
      } else {
        history.push(historyEntry);
      }
    },
    [filters, history]
  );
  const clearFilters = useCallback(
    evt => {
      evt.preventDefault();
      setFilters({});
      const historyEntry: LocationDescriptor = { search: '' };
      if (!pathsUsingFilters.includes(history.location.pathname)) {
        // If we're not on a path that supports using filters, go to the reports page by default.
        historyEntry.pathname = PATH_REPORTS;
      }
      history.push(historyEntry);
    },
    [history]
  );

  return { applyFilters, clearFilters, onChange, filters };
}
