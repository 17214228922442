import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import api, { dataSourcesUri } from '../../api';
import { dataSourceProperties } from '../../values/dataSource';

const waiting = createAction('dataSourceUpload/waiting');
const success = createAction('dataSourceUpload/success');
const fail = createAction('dataSourceUpload/fail');
const progress = createAction('dataSourceUpload/progress');
export const resetDataSources = createAction('dataSourceUpload/reset');

function copyForEachDataSourceProperty(value) {
  return Object.fromEntries(dataSourceProperties.map(property => [property, value]));
}

const initialState = {
  data: copyForEachDataSourceProperty({}),
  waiting: copyForEachDataSourceProperty(false),
  error: copyForEachDataSourceProperty(null),
  progress: copyForEachDataSourceProperty(0),
};

let currentAjaxRequest;

export function uploadFile(file, apiProperty, fileType, maxPages) {
  return async dispatch => {
    dispatch(waiting(apiProperty));
    const formData = new FormData();
    formData.append('file', file);
    formData.append('typeId', fileType);
    if (maxPages) {
      formData.append('maxPages', maxPages);
    }
    try {
      const responseData = (
        await api({
          method: 'POST',
          url: dataSourcesUri,
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress({ loaded, total }) {
            dispatch(progress([apiProperty, loaded / total]));
          },
        })
      ).data.data;
      dispatch(success([apiProperty, responseData]));
      return responseData;
    } catch (e) {
      dispatch(fail([apiProperty, e]));
      throw e;
    }
  };
}

/** Cancel any file upload in progress, and delete any file already uploaded. */
export function cancelUploads() {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(resetDataSources());
    return new Promise(resolve => {
      if (currentAjaxRequest) {
        currentAjaxRequest.abort();
      }
      setTimeout(() => {
        for (const dataSourceProperty of dataSourceProperties) {
          let dataSourceData;
          let uuid;
          dataSourceData = selectDataSourcesByProperties(state)[dataSourceProperty].data;
          uuid = dataSourceData && dataSourceData.uuid;
          if (uuid) {
            // Delete the uploaded data source.
            api.delete(`${dataSourcesUri}/${uuid}`);
          }
        }
        resolve();
      });
    });
  };
}

// Selects the data in a format with per-property set of data, error and waiting.
export const selectDataSourcesByProperties = createSelector(
  state => state.dataSourceUpload,
  dataSourceUpload => {
    const ret = {};
    for (const property of dataSourceProperties) {
      ret[property] = {
        data: dataSourceUpload.data[property],
        error: dataSourceUpload.error[property],
        waiting: dataSourceUpload.waiting[property],
        progress: dataSourceUpload.progress[property],
      };
    }
    return ret;
  }
);

/**
 * Returns true if any upload is being waited on.
 */
export const selectAnyUploadWaiting = createSelector(
  state => state.dataSourceUpload,
  dataSourceUpload => {
    for (const property of dataSourceProperties) {
      if (dataSourceUpload.waiting[property]) {
        return true;
      }
    }
    return false;
  }
);

const dataSourceUploadReducer = createReducer(initialState, {
  [waiting]: (state, { payload: apiProperty }) => {
    state.waiting[apiProperty] = true;
    state.error[apiProperty] = null;
    state.progress[apiProperty] = 0;
  },
  [success]: (state, { payload: [apiProperty, fileResponseData] }) => {
    // Set the file data for the given apiProperty (thumbnail or pdf).
    state.data[apiProperty] = fileResponseData;
    state.waiting[apiProperty] = false;
  },
  [fail]: (state, { payload: [apiProperty, error] }) => {
    state.error[apiProperty] = error;
    state.waiting[apiProperty] = false;
  },
  [progress]: (state, { payload: [apiProperty, progress] }) => {
    state.progress[apiProperty] = progress;
  },
  [resetDataSources]: () => initialState,
});

export default dataSourceUploadReducer;
