export const singleSelect = 'single_select';
export const multiSelect = 'multi_select';
export const dateType = 'date';

export const selectTypes = [singleSelect, multiSelect];

export const apiFieldTypes = [singleSelect, multiSelect, 'single_line', 'multi_line'];

export const channelUuid = '29220C5B-E8D0-343E-B4EF-A9E6326D939F';
export const industryUuid = '61AD4AC2-BF8B-3773-97CC-FE0C4B701AD1';
export const subSegmentUuid = '91BDFB21-2051-3B22-B996-A8A6E65B300E';
export const tickerUuid = 'A8387011-E386-3EF0-9E14-CF60B7BFF951';
export const sentimentUuid = '6F85AE1D-CB33-33C3-85AC-3615D6E87C81';
export const yearUuid = '1C9113C5-E3D6-3A03-917D-BC769EB9E60A';
export const quarterUuid = '5BEF7981-BA2D-3B2A-BD8E-AFB5E42F962E';
export const reportTypeUuid = '4B20D795-F4AB-36C3-BAE3-FF81D9232E43';
export const summaryUuid = '3A9581FC-0D92-39B5-8B6C-F655365AC945';
export const publishDateUuid = '35BFFB97-8D53-4A39-A74C-7411A71139B6';
export const aboutTheCompaniesUuid = '780E7176-03AA-4064-A96B-257B5DCCA0AA';
export const sentimentHtmlUuid = '815025FA-1D78-4FE4-91CE-8E3984DC7A04';
export const themeInFocusUuid = '4D8027B9-726F-452F-9960-8903CC693170';
export const periodStartUuid = '5F26A6BD-188D-4A38-BA12-D5391D029F55';
export const periodEndUuid = '6925FB36-CAA8-4E20-80CE-FA87B1F16C1C';
export const collectionStartUuid = '07D916AC-C1F0-42E4-B33F-2E61C5D63B98';
export const collectionEndUuid = 'C96360FB-165E-4E86-A3B8-5CB8830F39C1';

export type ReportFieldUuid =
  | typeof channelUuid
  | typeof industryUuid
  | typeof subSegmentUuid
  | typeof tickerUuid
  | typeof sentimentUuid
  | typeof yearUuid
  | typeof quarterUuid
  | typeof reportTypeUuid
  | typeof summaryUuid
  | typeof publishDateUuid
  | typeof aboutTheCompaniesUuid
  | typeof sentimentHtmlUuid
  | typeof themeInFocusUuid
  | typeof periodStartUuid
  | typeof periodEndUuid
  | typeof collectionStartUuid
  | typeof collectionEndUuid;

export const sentimentValuePositiveUuid = '3153E75A-F2FC-30AA-B6FD-9F855510C86B';
export const sentimentValueNegativeUuid = '1DD98F78-BA96-3807-938A-F568958DCFA3';

export const tenantUuid = '3923C14C-A124-3A53-BB69-D7628AFB8218';
