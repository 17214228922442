import { createFetchSlice, getInitialState } from '../sliceCreators/fetchSliceCreator';
import { createSelector } from 'reselect';
import { FieldOptionWithField } from '../../types/entities';
import { fieldOptionsUri } from '../../api';

const slice = createFetchSlice('fieldOption', {}, getInitialState<FieldOptionWithField>());

export function loadFieldOption(uuid: string, options = {}) {
  return slice.loadUnlessTokenSame({
    uri: `${fieldOptionsUri}/${uuid}`,
    ...options,
  });
}

const fieldOptionReducer = slice.reducer;
const fieldOptionSelector = slice.selector;
const fieldOptionReset = slice.actions.reset;

export { fieldOptionSelector, fieldOptionReset };

export const fieldOptionDataSelector = createSelector(fieldOptionSelector, v => v.data);

export const fieldOptionSuccess = slice.actions.success;

export default fieldOptionReducer;
