import { init as sentryInit } from '@sentry/browser';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import App from './App';
import AppBoundary from './AppBoundary';
import backendVars from './backendVars';
import './fontLibrary';
import history from './history';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import store from './store';
import { loadingChunkFailedRegexp } from './store/errorHandling';

const firefoxVersion = window.navigator.userAgent?.match(/Firefox\/(\d+).(\d+)/)?.[1];
const oldBrowserIgnoreRegex = /Minified React error #31|Objects are not valid as a React child \(found: object with keys {\$\$typeof, type, key, ref, props, _owner}\)/;
const isOldFirefox = firefoxVersion && Number(firefoxVersion) <= 78;

// https://github.com/emotion-js/emotion/issues/1105#issuecomment-885009697
if (import.meta.env.DEV && !window.originalConsole) {
  window.originalConsole = window.console;
  //eslint-disable-next-line no-console
  const log = console.error.bind(console);
  //eslint-disable-next-line no-console
  console.error = (...args) => {
    if (
      typeof args[0] === 'string' &&
      args[0].includes('The pseudo class') &&
      args[0].includes('is potentially unsafe when doing server-side rendering. Try changing it to')
    ) {
      return;
    }
    log(...args);
  };
}

if (backendVars.sentryDsn) {
  sentryInit({
    dsn: backendVars.sentryDsn,
    release: backendVars.gitSha1,
    beforeSend(event, hint) {
      if (isOldFirefox && oldBrowserIgnoreRegex.test(event?.exception?.values?.[0]?.value)) {
        //eslint-disable-next-line no-console
        console.warn('not reporting error');
        return null;
      }

      const error = hint.originalException;
      if (error?.message && loadingChunkFailedRegexp.test(error.message)) {
        console.warn('Not reporting chunk failed error, and refreshing the page.');
        window.location.reload();
        return null;
      }

      return event;
    },
  });
}

const app = (
  <Provider store={store}>
    <Router history={history}>
      <AppBoundary>
        <App />
      </AppBoundary>
    </Router>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
