import React from 'react';
import Spinner from '../Spinner';

/**
 * Absolutely positioned spinner with a 100% width/height container that's translucent.
 */
function FullPageSpinner() {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(255,255,255,0.5)',
        }}
      />
      <Spinner />
    </div>
  );
}

export default FullPageSpinner;
