export const TYPE_REPORT_FILE = 1;
export const TYPE_THUMBNAIL = 2;
export const dataSourceTypes = [TYPE_REPORT_FILE, TYPE_THUMBNAIL] as const;

export const dataSourceProperties = [
  'reportDataSourceUuid',
  'thumbnailDataSourceUuid',
  'earningsFileDataSourceUuid',
  'expertDocumentDataSourceUuid',
] as const;
