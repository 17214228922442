export const lightBlue = '#0091FF';
export const blue = '#262268';
export const darkBlue = '#18115F';
export const red = '#FA2200';
export const darkerRed = '#e02020';
export const gray = '#b5b6b9';
export const darkGray = '#6C6E74';
export const lightGray = '#c8cbcf';
export const darkerGray = '#565656'; // Used for texts typically.

export const headerHeight = '4.5rem';

export const boxShadowRule = 'box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.16);';

export const green = '#38761d';
export const reportRed = '#cc0000';
