const TYPE = 'NewFieldOptionValue';

/**
 * Represents a new value typed into a combobox that isn't saved yet,
 * and thus does not yet have a UUID, only a label.
 */
export type NewValueRepresentation = {
  objectType: 'NewFieldOptionValue';
  value: string;
};

/**
 * Wraps a value in an object representing that it's a new value.
 * This could be done using a class instance, but Redux only accepts plain objects.
 */
export function toNewValueRepresentation(value: string): NewValueRepresentation {
  return {
    objectType: TYPE,
    value,
  };
}

/**
 * Checks if the passed argument represents a new value created via a combobox.
 */
export function isNewValueRepresentation(obj): obj is NewValueRepresentation {
  return obj && typeof obj === 'object' && obj.objectType === TYPE;
}
