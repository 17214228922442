import axios from 'axios';
import { clearFromStorage, getFromStorage } from './utils/remember';
import { getCookie } from './utils/browser';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { authSuccessReponse, logout } from './store/reducers/authReducer';
import store from './store';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let base = import.meta.env.VITE_API_ENDPOINT;

const api = axios.create({
  baseURL: `${base}`,
  withCredentials: true, // This is for sending cookie e.g. for xdebug.
});

api.interceptors.request.use(config => {
  if (!config.headers) {
    config.headers = {};
  }
  if (window.userId && window.userId !== getFromStorage('userId')) {
    // The visitor must have logged out in a different tab and logged in as a new user there.
    // Must force a logout, otherwise the FE will show things as if you were logged in as the
    // old user, but call API endpoints as if they were the new user.
    // A consequence of this is the visitor will get logged out in all tabs.
    doLogout();
  }
  config.headers.Authorization = 'Bearer ' + getFromStorage('token');
  config.headers.Accept = 'application/json';
  // Forward xdebug cookie to the API.
  const xdebugSessionValue = getCookie('XDEBUG_SESSION');
  const clockworkProfileValue = getCookie('clockwork-profile');
  if (!config.params) {
    config.params = {};
  }
  if (xdebugSessionValue) {
    config.params.XDEBUG_SESSION_START = xdebugSessionValue;
  }
  if (clockworkProfileValue != null) {
    config.params['clockwork-profile'] = clockworkProfileValue;
  }
  return config;
});

// This promise ensures that more than one refresh would not happen at a time.
// When requesting to refresh a token, this promise should be set so that while waiting for the
// token refresh response, newer calls to refresh the token would simply all wait for the same first
// token refresh response to arrive. Then once the promise is resolved, the variable should be
// unset so future invocations would call the refresh endpoint for real again.
// Though of course when successfully refreshing, the interceptor should hopefully not even get
// invoked anymore thanks to not getting 401 responses anymore thanks to the token refreshing.
let refreshingPromise: Promise<void> | null = null;

createAuthRefreshInterceptor(
  api,
  error => {
    if (refreshingPromise) {
      return refreshingPromise;
    }

    // Maybe the token just expired and we need to refresh it. Let's try that.
    refreshingPromise = api
      .post('/v1/refresh', { refreshToken: getFromStorage('refreshToken') })
      .then(response => {
        // Refreshing worked. The axios-auth-refresh library automatically retries the
        // the original request that was going to be made as long as we return a resolved promise.
        store.dispatch(authSuccessReponse(response.data));
        return Promise.resolve();
      })
      .catch(() => {
        // Couldn't recover by refreshing the token.
        doLogout();
        return Promise.reject(error);
      })
      .finally(() => {
        // Clear the promise so that newer calls to this interceptor would initiate a fresh API call.
        refreshingPromise = null;
      });

    return refreshingPromise;
  },
  { statusCodes: [401], pauseInstanceWhileRefreshing: true }
);

createAuthRefreshInterceptor(
  api,
  error => {
    let retryAfter;
    if ((retryAfter = error.response.headers['retry-after'])) {
      // Request throttled. Try again after "Retry-After" amount of seconds.
      return new Promise(resolve => {
        setTimeout(resolve, 1000 * retryAfter);
      });
    } else {
      return Promise.reject(error);
    }
  },
  { statusCodes: [429] }
);

function doLogout() {
  localStorage.clear();
  clearFromStorage('token');
  store.dispatch(logout());
}

export const reportsUri = '/v1/reports';
export const readershipStatisticsUri = `${reportsUri}/readership-statistics`;
export const accountsUri = '/v1/accounts';
export const groupsUri = '/v1/groups';
export const usersUri = '/v1/users';
export const rolesUri = '/v1/roles';
export const attributesUri = '/v1/attributes';
export const dataSourcesUri = '/v1/data_sources';
export const fieldOptionsUri = '/v1/field_options';
export const channelDynamicsBaseUri = '/v1/channel_dynamics';
export const companiesUri = `${channelDynamicsBaseUri}/companies`;
export const biosUri = `${channelDynamicsBaseUri}/bios`;
export const notesUri = `${channelDynamicsBaseUri}/notes`;
export const notesByCompanyUri = `${notesUri}/by_company`;
export const callsUri = `${channelDynamicsBaseUri}/calls`;
export const podcastsUri = `${channelDynamicsBaseUri}/podcast`;

export type ResponseEnvelope<T> = {
  data: T;
};

export type MetaPagination = {
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number;
};

export type PaginatedResponseEnvelope<T> = ResponseEnvelope<T> & {
  links: {
    next: string | null;
  };
  meta: MetaPagination;
};

export default api;
