import api, { usersUri } from '../../api';
import { normalizeError } from '../errorHandling';
import { createAdminEditSlice } from '../sliceCreators/adminEditSliceCreator';
import { loadAdminUsers } from './adminUsersReducer';
import { loadControlPanelWidgets } from './controlPanelWidgetsReducer';

async function apiSaveUser(uuid, data) {
  let response;
  if (uuid) {
    response = await api.put(`${usersUri}/${uuid}`, data);
  } else {
    response = await api.post(usersUri, data);
  }

  return response.data.data;
}

export function saveUser() {
  return async (dispatch, getState) => {
    dispatch(waiting());
    try {
      const adminUserEdit = adminUserEditSelector(getState());
      const { uuid, ...data } = adminUserEdit.data;
      await apiSaveUser(uuid, data);
      dispatch(success());

      // Trigger reloading control panel and global users.
      dispatch(loadAdminUsers());
      dispatch(loadControlPanelWidgets());
    } catch (error) {
      dispatch(fail(normalizeError(error)));
      throw error;
    }
  };
}

export function loadAdminEditUser(userId, preloadData = {}) {
  return async dispatch => {
    if (!userId) {
      dispatch(preload(preloadData));
      // Creating a new user. No need to load any data.
      return;
    }
    dispatch(clear());
    dispatch(preload((await api.get(`${usersUri}/${userId}`)).data.data));
  };
}

export const adminUserEditSelector = state => state.adminUserEdit;

const propertyWhitelist = ['uuid', 'name', 'email', 'password', 'role', 'accountUuid', 'group'];

const adminUserEditSlice = createAdminEditSlice('adminUserEdit', propertyWhitelist);

const { waiting, fail, success, clear, setField, preload } = adminUserEditSlice.actions;

export const userEditEditSetField = setField;

export default adminUserEditSlice.reducer;
