import { notesByCompanyUri } from '../../api';
import { createFetchSlice, getInitialState } from '../sliceCreators/fetchSliceCreator';
import { createSelector } from 'reselect';
import { NoteByCompany } from '../../types/entities';
import { reportShowDataSelector } from './reportShowReducer';

const slice = createFetchSlice('reportCompanyNotesPreview', {}, getInitialState<NoteByCompany[]>());

export function loadReportCompanyNotesPreview(reportUuid: string) {
  return slice.load({
    uri: `${notesByCompanyUri}?entityUuid=${reportUuid}`,
    stealth: true, // Always update the preview stealthily.
  });
}

const reportCompanyNotesPreviewReducer = slice.reducer;
const reportCompanyNotesPreviewSelector = slice.selector;
const reportCompanyNotesPreviewReset = slice.actions.reset;

export { reportCompanyNotesPreviewSelector, reportCompanyNotesPreviewReset };

export const reportCompanyNotesPreviewDataSelector = createSelector(
  reportCompanyNotesPreviewSelector,
  reportShowDataSelector,
  // Use the last loaded preview data, if available; otherwise take the company notes from the
  // loaded report.
  (companyNotes, reportShowData) => companyNotes.data || reportShowData?.companyNotes || []
);

export default reportCompanyNotesPreviewReducer;
