import { css } from '@emotion/react';
import { darkerGray } from '../../../styles/variables';
import { reportInputHeight } from '../../ReportEditPage/reportEditPageCss';

const inputCss = css`
  width: 100%;
  height: ${reportInputHeight};
  border: 0;
  border-bottom: 1px solid ${darkerGray};
  font-weight: bold;
  font-size: 1em;
  background-color: transparent;
`;

export default inputCss;
