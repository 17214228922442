import { createAction, createNextState, createReducer, createSelector } from '@reduxjs/toolkit';
import {
  difference,
  get,
  identity,
  isEqual,
  keyBy,
  mapValues,
  pick,
  set,
  trim,
  unset,
} from 'lodash-es';
import api, { dataSourcesUri, reportsUri, ResponseEnvelope } from '../../api';
import recursiveMap from '../../utils/recursiveMap';
import {
  channelUuid,
  publishDateUuid,
  selectTypes,
  singleSelect,
  subSegmentUuid,
  tickerUuid,
} from '../../values/apiFields';
import { dataSourceProperties } from '../../values/dataSource';
import { normalizeError } from '../errorHandling';
import { isNewValueRepresentation } from '../representations/newValue';
import {
  ATTRIBUTE_VALUES_PROPERTY,
  DEEPER_DIVE_PROPERTY,
  DYNAMIC_UUID_FIELDS_PROPERTY,
  EARNINGS_PROPERTY,
  KPI_VALUES_PROPERTY,
} from '../sliceCreators/adminEditSliceCreator';
import { resetDataSources } from './dataSourceUploadReducer';
import {
  addFieldOption,
  createFieldOption,
  fieldFormValuesSelector,
  loadFields,
  uuidLabelMapSelector,
} from './fieldsReducer';
import { ReportAttribute, reportShowDataSelector, ShowDataType } from './reportShowReducer';
import { RootState } from '../index';
import { reportCompanyNotesPreviewDataSelector } from './reportCompanyNotesPreviewReducer';
import { DynamicFields } from '../../types/entities';
import { ApiCallNote } from '../../utils/hooks/noteHooks';
import { lightFormat } from 'date-fns';
import { isFieldOptionExpired } from '../../utils/fields';

const reportEditWaiting = createAction('reportEdit/waiting');
const reportEditSuccess = createAction('reportEdit/success');
const reportEditFail = createAction('reportEdit/fail');
export const reportEditSetField = createAction<[string | Array<string>, any]>(
  'reportEdit/setField'
);
export const reportEditUnsetField = createAction<string | Array<string>>('reportEdit/unsetField');
export const reportEditSwapFields = createAction<[string | Array<string>, [number, number]]>(
  'reportEdit/swapFields'
);
export const reportEditReset = createAction('reportEdit/reset');
export const reportEditPreload = createAction('reportEdit/preload');
export const reportEditSetDefaultDeeperDive = createAction<ApiCallNote[]>(
  'reportEdit/setDefaultDeeperDive'
);

// Shape of the data that's sent to the API with POST/PUT.
type ReportOutputData = Omit<Pick<ShowDataType, typeof propertyWhitelist[number]>, 'uuid'> & {
  uuid?: string; // The UUID won't be there if it's a new report.
};

// Shape of the data while working on it locally. It's in a different format than the output
// to be able to work with it easier with object tree form binding.
export type ReportInputData = Omit<
  ReportOutputData,
  typeof ATTRIBUTE_VALUES_PROPERTY | typeof EARNINGS_PROPERTY | typeof DYNAMIC_UUID_FIELDS_PROPERTY
> & {
  [DYNAMIC_UUID_FIELDS_PROPERTY]: DynamicFields;
  [KPI_VALUES_PROPERTY]: { [key: string]: string | null };
  [EARNINGS_PROPERTY]: {
    [tickerUuid: string]: {
      [kpiUuid: string]: Omit<ShowDataType[typeof EARNINGS_PROPERTY], 'tickerUuid' | 'kpiUuid'>;
    };
  };
  shouldDelayPublish: boolean;
  delayPublishTime: string;
};

// Subset of the "show" data ommitting some properties not necessary for showing a report.
export type PreviewableShowData = Omit<
  ShowDataType,
  'createdAt' | 'updatedAt' | 'publishedAt' | 'publishedAtInEst'
>;

const initialData: ReportInputData = {
  earnings: {},
  kpi: {},
  fields: {},
  groups: [],
  shouldDelayPublish: true,
  delayPublishTime: '00:00',
};

const initialState = {
  data: initialData,
  waiting: false,
  error: null as null | ReturnType<typeof normalizeError>,
  defaultDeeperDive: [] as ApiCallNote[],
};

async function apiSaveReport(inputData) {
  const { uuid, ...data } = inputData;
  const response = await api.request<ResponseEnvelope<ShowDataType>>({
    method: uuid ? 'PUT' : 'POST',
    url: uuid ? `${reportsUri}/${uuid}` : reportsUri,
    data,
  });

  return response.data.data;
}

export function saveReport(fieldOptionsByUuid, published = null) {
  return async (dispatch, getState) => {
    dispatch(reportEditWaiting());
    try {
      await saveNewFieldOptions(dispatch, getState);

      const payload = { ...reportEditDataAsOutputSelector(getState()), published };
      if (isEqual(payload.deeperDive, reportEditDefaultDeeperDiveSelector(getState()))) {
        // The current deeper dive is equal to what's considered the empty deeper dive.
        // So make sure they are not sent in.
        payload.deeperDive = [];
      }
      if (!payload.uuid && payload.fields[tickerUuid]) {
        payload.fields = createNextState(payload.fields, draft => {
          draft[tickerUuid] = payload.fields[tickerUuid]!.filter(
            ticker =>
              !fieldOptionsByUuid[ticker] ||
              !isFieldOptionExpired(fieldOptionsByUuid[ticker], payload.fields[publishDateUuid])
          );
        });
      }

      const report = await apiSaveReport(payload);
      dispatch(reportEditSuccess());
      dispatch(resetDataSources());
      // Update the field list in case a new report title was inserted.
      dispatch(loadFields({ force: true, stealth: true }));

      return report;
    } catch (error) {
      dispatch((reportEditFail as any)(normalizeError(error)));
      throw error;
    }
  };
}

/**
 * Dave new field options via combobox
 *
 * @param dispatch
 * @param getState
 */
async function saveNewFieldOptions(dispatch, getState: () => RootState) {
  const newFieldOptionPromises: any[] = [];
  const dynamicSelectedFieldsByUuid = selectDynamicFieldsSelectedsByUuid(getState());
  if (!dynamicSelectedFieldsByUuid) {
    throw new Error(`There are no dynamic fields.`);
  }
  for (const [fieldUuid, value] of Object.entries(dynamicSelectedFieldsByUuid) as any) {
    if (!isNewValueRepresentation(value)) {
      // This is not a new value, but rather an existing value, so skip over this.
      continue;
    }
    if (fieldUuid !== channelUuid) {
      throw new Error('Currently only Channels are supported.');
    }
    const parentFieldUuid = subSegmentUuid;
    const parentFieldOptionUuid = dynamicSelectedFieldsByUuid[parentFieldUuid];
    if (!parentFieldOptionUuid) {
      throw new Error(`Did not expect to not find parentFieldOptionUuid for ${parentFieldUuid}`);
    }
    newFieldOptionPromises.push(
      createFieldOption(fieldUuid, value.value, parentFieldOptionUuid).then(result => {
        // Since the new field option was created, let's add it to the field options list.
        dispatch(
          addFieldOption([
            fieldUuid,
            result.uuid,
            result.option,
            parentFieldUuid,
            parentFieldOptionUuid,
          ])
        );
        // Let's also change the field value from a representation of a new value into an
        // existing value.
        dispatch(
          (reportEditSetField as any)([[DYNAMIC_UUID_FIELDS_PROPERTY, fieldUuid], result.uuid])
        );
      })
    );
  }

  return Promise.all(newFieldOptionPromises);
}

/**
 * Clears out any selected dropdown options that have since become invalid
 * e.g. if the Sector changed, and the currently selected Sub Segment is not valid anymore.
 *
 * @param dispatch
 * @param state
 */
export function ensureFieldOptionsValid(dispatch, state) {
  const selectedFieldsByUuid = selectDynamicFieldsSelectedsByUuid(state);
  const fieldsAndOptions = selectDynamicFieldOptionsByUuid(state);
  if (!fieldsAndOptions) {
    return;
  }
  for (let fieldUuid in selectedFieldsByUuid) {
    if (!Object.prototype.hasOwnProperty.call(selectedFieldsByUuid, fieldUuid)) {
      continue;
    }
    const selectedValue: string | undefined = selectedFieldsByUuid[fieldUuid];
    const dropdownFieldDefinition = fieldsAndOptions[fieldUuid];
    if (!dropdownFieldDefinition || dropdownFieldDefinition.type !== singleSelect) {
      // We only care about <select> types here. Also, only single selects are supported for now.
      continue;
    }
    if (dropdownFieldDefinition.options.find(({ value }) => selectedValue === value)) {
      // Selected option is valid.
      continue;
    }
    if (isNewValueRepresentation(selectedValue)) {
      // Represents a new value created by a Creatable select.

      if (fieldUuid !== channelUuid || selectedFieldsByUuid[subSegmentUuid]) {
        // Silly way to make sure that if it's this is the Channel value, then it shouldn't
        // be selectable at all if there's no Subsegment set.
        continue;
      }
    }

    // Clear the value, because it's not among the possible options anymore.
    dispatch((reportEditUnsetField as any)([DYNAMIC_UUID_FIELDS_PROPERTY, fieldUuid]));
  }
}

const propertyWhitelist = [
  'uuid',
  DYNAMIC_UUID_FIELDS_PROPERTY,
  ATTRIBUTE_VALUES_PROPERTY,
  'groups',
  ...dataSourceProperties,
  'earnings',
  DEEPER_DIVE_PROPERTY,
] as const;

/**
 * Transforms data coming in to a format we can work with better.
 *
 * @param data
 */
function transformDataInput(data: ShowDataType): ReportInputData {
  const {
    [ATTRIBUTE_VALUES_PROPERTY]: attributes,
    [EARNINGS_PROPERTY]: earningsArray,
    ...restData
  } = data;
  const kpi: { [key: string]: string } = {};

  for (const { attributeUuid, attributeOptionUuid, fieldOptionUuid } of attributes) {
    const path = fieldOptionUuid ? [attributeUuid, fieldOptionUuid] : [attributeUuid];
    set(kpi, path, attributeOptionUuid);
  }

  const earningsObj = {};
  for (const { tickerUuid, kpiUuid, ...rest } of earningsArray) {
    set(earningsObj, [tickerUuid, kpiUuid], rest);
  }

  const whitelistedData = pick<typeof data, typeof propertyWhitelist[number]>(
    data,
    propertyWhitelist
  );
  // Drop "attributes".
  const { attributes: _, ...inputData } = whitelistedData;

  const publishedAtInEst =
    data.publishedAtInEst != null ? new Date(data.publishedAtInEst) : data.publishedAtInEst;
  const delayPublishTime =
    publishedAtInEst?.getSeconds() === 0 && publishedAtInEst.getMilliseconds() === 0
      ? lightFormat(publishedAtInEst, 'HH:mm')
      : '00:00';

  return {
    ...inputData,
    ...restData,
    [KPI_VALUES_PROPERTY]: kpi,
    [EARNINGS_PROPERTY]: earningsObj,
    shouldDelayPublish: data.publishStatus !== 'published',
    delayPublishTime,
  };
}

/**
 * Transforms data going out.
 * @param data
 * @returns {{attributes: []}}
 */
function transformDataOutput(data: ReportInputData): ReportOutputData {
  const { [KPI_VALUES_PROPERTY]: kpi, [EARNINGS_PROPERTY]: earningsObj, ...restData } = data;

  const attributes: Array<ReportAttribute> = [];
  for (const [attributeUuid, attributeOptionUuidOrObject] of Object.entries(kpi)) {
    const isMultiValuedAttribute =
      attributeOptionUuidOrObject && typeof attributeOptionUuidOrObject === 'object';
    const toIterate = isMultiValuedAttribute
      ? Object.entries(attributeOptionUuidOrObject as any)
      : [[null, attributeOptionUuidOrObject]];
    for (const [fieldOptionUuid, attributeOptionUuid] of toIterate as [[string | null, string]]) {
      if (!attributeOptionUuid) {
        // Unset field; do not include it in the array.
        continue;
      }
      const attribute: ReportAttribute = {
        attributeUuid,
        attributeOptionUuid,
      };
      if (fieldOptionUuid) {
        attribute.fieldOptionUuid = fieldOptionUuid;
      }
      attributes.push(attribute);
    }
  }

  const earningsArray: Array<any> = [];

  for (const [tickerUuid, withinTicker] of Object.entries(earningsObj)) {
    for (const [
      kpiUuid,
      { date = null, value = null, correctness = null, ...restWithinKpi },
    ] of Object.entries(withinTicker) as any) {
      earningsArray.push({ tickerUuid, kpiUuid, date, value, correctness, ...restWithinKpi });
    }
  }

  return {
    ...restData,
    [ATTRIBUTE_VALUES_PROPERTY]: attributes,
    [EARNINGS_PROPERTY]: earningsArray,
  };
}

const reportEditSelector = (state: RootState) => state.reportEdit;

// Transformed report data.
export const reportEditDataSelector = createSelector(
  reportEditSelector,
  reportEdit => reportEdit.data
);

/**
 * The default Deeper Dive for this report.
 *
 * When a report is being edited/created, if we know the report's channel, the default deeper dive
 * must be set.
 * It can be used for two things:
 * 1. If Deeper Dive has never been set for the report, you can use this as a default.
 * 2. If there was Deeper Dive, you can find if there are any new KPIs that have since been
 *  associated with the channel, but have not yet been applied to the old report yet; and then
 *  make sure to amend the old report with the new KPI notes.
 */
export const reportEditDefaultDeeperDiveSelector = createSelector(
  reportEditSelector,
  reportEdit => reportEdit.defaultDeeperDive
);

// Selected values of the report in the dynamic "fields" property.
export const selectDynamicFieldsSelectedsByUuid = createSelector(
  reportEditDataSelector,
  reportEditData => reportEditData?.[DYNAMIC_UUID_FIELDS_PROPERTY]
);

// Fields as keys, and their values as labels.
export const selectedFieldValueLabelsSelector = createSelector(
  selectDynamicFieldsSelectedsByUuid,
  uuidLabelMapSelector,
  (fieldsObj, uuidLabelMap) =>
    mapValues(fieldsObj, value => {
      if (!value) {
        return void 0;
      }
      if (isNewValueRepresentation(value)) {
        return value.value;
      }
      return recursiveMap(value, uuidValue => uuidLabelMap[uuidValue]);
    })
);

// The report edit data transformed into the payload format for saving.
export const reportEditDataAsOutputSelector = createSelector(
  reportEditDefaultDeeperDiveSelector,
  reportEditDataSelector,
  (defaultDeeperDive, reportEditData) => {
    if (!reportEditData) {
      return reportEditData;
    }
    const inputData = { ...reportEditData };

    if (!inputData.deeperDive?.length) {
      inputData.deeperDive = defaultDeeperDive;
    }

    return transformDataOutput(inputData);
  }
);

// Adds some additional properties to the report edit payload for use in previews.
export const reportEditDataPreparedForPreviewSelector = createSelector(
  reportEditDataAsOutputSelector,
  reportCompanyNotesPreviewDataSelector,
  (reportEditData: ReportOutputData | null, companyNotes): ShowDataType | null => {
    if (!reportEditData) {
      return null;
    }
    const dataSourcesBaseUri = `${api.defaults.baseURL}${dataSourcesUri}`;
    const reportsPdfBaseUri = `${dataSourcesBaseUri}/${reportEditData.reportDataSourceUuid}`;
    const reportsThumbnailBaseUri = `${dataSourcesBaseUri}/${reportEditData.thumbnailDataSourceUuid}`;
    return {
      uuid: '',
      ...reportEditData,
      companyNotes,
      reportDownloadUrl: `${reportsPdfBaseUri}/download`,
      reportPreviewUrl: `${reportsPdfBaseUri}/preview`,
      thumbnailDownloadUrl: `${reportsThumbnailBaseUri}/download`,
      thumbnailPreviewUrl: `${reportsThumbnailBaseUri}/preview`,
      publishStatus: 'published',
    };
  }
);

/**
 * Grabs the report edit data, but makes sure to amend the Deeper Dive section to include
 * missing channel KPI notes that were missing from what came from the API.
 * The missing KPIs are likely due to a new KPI added to a channel after a report had been
 * saved earlier without that new KPI.
 *
 * This is needed to make sure that the dirty-checker would not think the form is dirty when
 * loading a report for editing that has new note Channel KPIs missing, and also to be able
 * to edit those new notes.
 */
export const reportShowDataForReportEditSelector = createSelector(
  reportShowDataSelector,
  reportEditDefaultDeeperDiveSelector,
  (reportShowData, defaultDeeperDive) => {
    if (!defaultDeeperDive || !reportShowData || !reportShowData.deeperDive?.length) {
      // There's another bit of code that makes sure that if the deeper dive is completely
      // empty or missing from the API, that it get set with the default deeper dive.
      // TODO: don't do that; handle setting empty deeper dive to the default here.
      return reportShowData;
    }
    const notesByKpiUuid: { [kpiUuid: string]: ApiCallNote } = keyBy(defaultDeeperDive, 'kpiUuid');
    const defaultKpiUuids = defaultDeeperDive.map(v => v.kpiUuid).filter(identity) as string[];
    const reportShowDeeperDiveKpiUuids = reportShowData.deeperDive
      .map(v => v.kpiUuid)
      .filter(identity) as string[];
    const missingKpiUuids = difference(defaultKpiUuids, reportShowDeeperDiveKpiUuids);
    const missingNotes = missingKpiUuids.map(kpiUuid => notesByKpiUuid[kpiUuid]);

    if (!missingNotes.length) {
      return reportShowData;
    }

    const newDeeperDive = [...reportShowData.deeperDive];
    // Insert the missing KPI notes before the last "General Takeaways" note.
    newDeeperDive.splice(newDeeperDive.length - 1, 0, ...missingNotes);

    return { ...reportShowData, deeperDive: newDeeperDive };
  }
);

// This is purely for comparing data for dirtiness checking.
const reportShowDataAsEditDataOutputSelector = createSelector(
  reportEditDefaultDeeperDiveSelector,
  reportShowDataForReportEditSelector,
  (defaultDeeperDive, reportShowData) => {
    const reportInputData = {
      ...(reportShowData ? transformDataInput(reportShowData) : initialState.data),
    };
    if (!reportInputData.deeperDive?.length) {
      reportInputData.deeperDive = defaultDeeperDive;
    }
    return transformDataOutput(reportInputData);
  }
);

// Dirty checker.
export const anyUnsavedChangesSelector = createSelector(
  reportEditDataAsOutputSelector,
  reportShowDataAsEditDataOutputSelector,
  (reportEditDataOutput, reportShowDataAsOutput) => {
    // debug:
    //console.info('edit', JSON.stringify(reportEditDataOutput));
    //console.info('show', JSON.stringify(reportShowDataAsOutput));
    return !isEqual(reportEditDataOutput, reportShowDataAsOutput);
  }
);

// Returns possible options for select fields, taking into account options being filtered by other options.
export const selectDynamicFieldOptionsByUuid = createSelector(
  fieldFormValuesSelector,
  selectDynamicFieldsSelectedsByUuid,
  ({ fields }, selectedFieldsByUuid) =>
    selectedFieldsByUuid
      ? Object.fromEntries(
          fields
            // Only include <select> type fields.
            .filter(({ type }) => selectTypes.includes(type))

            .map(fieldDefinition => ({
              ...fieldDefinition,
              options: fieldDefinition.options.filter(option =>
                option.parentFieldOptionUuid
                  ? // Field options depends on other field having a certain value selected.
                    selectedFieldsByUuid[option.parentFieldUuid!] === option.parentFieldOptionUuid
                  : true
              ),
            }))
            .map(fieldDefinition => [fieldDefinition.uuid, fieldDefinition])
        )
      : null
);

const reportEditReducer = createReducer(initialState, {
  [reportEditWaiting.type]: state => ({ ...state, error: null, waiting: true }),
  [reportEditSuccess.type]: state => ({ ...state, waiting: false }),
  [reportEditFail.type]: (state, { payload }) => ({ ...state, error: payload, waiting: false }),
  [reportEditSetField.type]: (state, { payload: [path, value] }) => {
    if (!state.data) {
      return;
    }
    set(state.data, path, typeof value === 'string' ? trim(value) : value);
  },
  [reportEditUnsetField.type]: (state, { payload: path }) => {
    unset(state.data, path);
  },
  [reportEditSwapFields.type]: (state, { payload: [path, [index1, index2]] }) => {
    const field = get(state.data, path);
    const swap = field[index1];
    field[index1] = field[index2];
    field[index2] = swap;
  },
  [reportEditReset.type]: () => initialState,
  [reportEditPreload.type]: (state: any, { payload }) => {
    state.data = transformDataInput(payload);
  },
  [reportEditSetDefaultDeeperDive.type]: (state, { payload }) => {
    state.defaultDeeperDive = payload;
  },
});

export const testing = {
  transformDataInput,
  transformDataOutput,
};

export default reportEditReducer;
