export const reportUuidParam = 'reportUuid';

export type RouteParams = {
  [reportUuidParam]?: string;
  companyUuid?: string;
};

export const PATH_DASHBOARD = '/';
export const PATH_LOGIN = '/login';
export const PATH_REPORTS = '/reports';
export const PATH_REPORT_CREATE = '/reports/create';
export const PATH_REPORT_SHOW = `/reports/:${reportUuidParam}`;
export const PATH_REPORT_EDIT = `/reports/:${reportUuidParam}/edit`;
export const PATH_ME_EDIT = `/me`;
export const PATH_CONTROL_PANEL = `/control-panel`;
export const PATH_ANALYTICS = `/analytics`;
export const PATH_KPI_AND_TICKERS = `/kpi-and-tickers`;
export const PATH_KPI = `/kpi`;
export const PATH_COMPANIES = `/companies/:companyUuid?`;
export const PATH_BIOS = `/bios`;
export const PATH_BIOS_SHOW = `${PATH_BIOS}/:bioUuid`;
export const PATH_BIOS_CREATE = `${PATH_BIOS}/create`;
export const PATH_BIOS_EDIT = `${PATH_BIOS_SHOW}/edit`;
export const PATH_PODCASTS = `/podcasts/:episodeId?`;
export const PATH_FORGOT_PASSWORD = `/forgot-password`;
export const PATH_SCHEDULE = `/release-calendar`;

export const SUBPATH_DEEPER_DIVE = '/deeper-dive';
export const SUBPATH_CALLS = '/calls';
export const SUBPATH_CALL = '/calls/:companyUuid';

export const HASH_POST_EARNINGS_SCORECARD = 'post-earnings-scorecard';

export const pathsUsingFilters = [PATH_REPORTS, PATH_ANALYTICS];
