import { createSlice } from '@reduxjs/toolkit';
import { ApiCallNote } from '../../utils/hooks/noteHooks';
import { set } from 'lodash-es';
import { RootState } from '../index';

const initialState = [] as ApiCallNote[];

const slice = createSlice({
  name: 'companyNotesEdit',
  initialState,
  reducers: {
    setField: (state, { payload: [path, value] }) => {
      set(state, path, value);
    },
    moveField: (state, { payload: [index1, index2] }) => {
      const swap = state[index1];
      state[index1] = state[index2];
      state[index2] = swap;
    },
    insertAnecdote: (state, { payload: [afterIndex, uuid] }) => {
      state.splice(afterIndex + 1, 0, {
        uuid,
        title: '',
        kpiUuid: null,
        html: '',
        visible: true,
        score: null,
      });
    },
    deleteAnecdote: (state, { payload: index }) => {
      state.splice(index, 1);
    },
    preload: (state, { payload }) => payload,
    reset: () => initialState,
  },
});

export const companyNotesEditActions = slice.actions;

const companyNotesEditReducer = slice.reducer;

export const companyNotesEditSelector = (state: RootState) => state[slice.name];

export default companyNotesEditReducer;
