import React from 'react';
import channelDynamicsTheme from './channelDynamicsTheme';
import { ReactComponent as DiamondComponent } from '../../assets/diamond.svg';
import { ReactComponent as TheNetworkEffectBigLogo } from '../../assets/tne-big.svg';
import loggedInTopLeftLogoSrc from '../../assets/tne-logo.png';

const darkGray = '#2D2E2D';

const theNetworkEffectTheme: typeof channelDynamicsTheme = {
  name: 'tne',
  topBackground: darkGray,
  sideBackground: darkGray,
  navBackground: '#403E40',
  primary: '#6F478E',
  loggedInTopLeftLogo: (
    <img alt="TNE - Powered by Channel Dynamics" src={loggedInTopLeftLogoSrc} height={70} />
  ),
  BigLogoComponent: TheNetworkEffectBigLogo,
  SmallLogoComponent: DiamondComponent,

  loginBackground: darkGray,
  loginTextColor: 'white',
};

export default theNetworkEffectTheme;
