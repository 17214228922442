import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { func, node } from 'prop-types';
import React from 'react';
import Button from '../UI/Button/Button';
import Flex from '../UI/Flex/Flex';

export const OuterContainer = styled.div(
  ({ theme }) => css`
    background-color: ${theme.loginBackground};
    height: 100%;
    color: ${theme.loginTextColor};
  `
);

export const Container = styled(Flex)`
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  align-items: stretch;
  justify-content: space-between;
  font-size: 0.8rem;
`;

export const LogoContainer = styled.div`
  width: 510px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Form = styled(Flex)`
  flex-direction: column;
`;
Form.defaultProps = {
  as: 'form',
};

export const InputContainer = styled.div(
  ({ theme }) => css`
    width: 100%;
    border-bottom: 2px solid ${theme.primary};
    margin-bottom: 20px;
  `
);
export const Input = styled.input(
  ({ theme }) => css`
    width: 100%;
    height: 36px;
    color: ${theme.loginTextColor};
    border: 0;
    background-color: transparent;
    font-size: 0.8rem;
    padding: 10px 5px;
  `
);
export const LoginButton = styled(Button)`
  width: 100%;
`;

export const FormInputsContainer = styled.div`
  width: 260px;
  margin-bottom: 40px;
  min-height: 132px;
  display: flex;
  align-items: stretch;
  text-align: center;
  flex-direction: column;
`;

export const LinksContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.2rem;
`;

export const FailureContainer = styled.div`
  p:first-child,
  style:first-child + p {
    margin-top: 0;
  }
`;
function LoggedOutLayout({ children, onSubmit }) {
  const theme = useTheme();
  const showLogoOnTopOfForm = theme.name === 'tne';

  return (
    <OuterContainer>
      <Container>
        {!showLogoOnTopOfForm && (
          <LogoContainer>
            <theme.BigLogoComponent style={{ width: 300, fill: theme.primary }} />
          </LogoContainer>
        )}

        <Form onSubmit={onSubmit} style={{ margin: showLogoOnTopOfForm ? 'auto' : 0 }}>
          {children}
        </Form>
      </Container>
    </OuterContainer>
  );
}

LoggedOutLayout.propTypes = {
  children: node,
  onSubmit: func,
};

export default LoggedOutLayout;
