import { createSelector, createSlice } from '@reduxjs/toolkit';
import { normalizeError } from '../errorHandling';
import { accountsDataSelector } from './accountsReducer';
import { selectedAccountUuidSelector } from './adminAccountsReducer';
import { loadAdminUsers } from './adminUsersReducer';
import { fetchGroups, loadGroups } from './groupsReducer';

const initialState = {
  selectedUuid: null,
  waiting: true,
  error: null,
  data: [],
};

export function loadAdminGroups(options = {}) {
  return async (dispatch, getState) => {
    const state = getState();
    if (options.reset) {
      dispatch(reset());
    }
    const accounts = accountsDataSelector(state);
    const selectedAccountUuid = selectedAccountUuidSelector(state);
    if (!selectedAccountUuid && accounts.length) {
      throw new Error('No account selected.');
    }

    dispatch(groupsFetching());
    try {
      const groups = accounts.length ? await fetchGroups([selectedAccountUuid]) : [];
      dispatch(groupsSuccess(groups));
      const localState = adminGroupsSelector(getState());

      // Ensure a valid UUID is selected.
      if (
        !localState.selectedUuid ||
        !groups.find(({ uuid }) => uuid === localState.selectedUuid)
      ) {
        await dispatch(
          selectAdminGroupUuid(localState.data.length ? localState.data[0].uuid : null)
        );
      }
    } catch (e) {
      dispatch(groupsFail(normalizeError(e)));
      throw e;
    }
  };
}

export function deleteGroup(uuid) {
  return dispatch => {
    // Delete the group from this reducer.
    dispatch(adminGroupDelete(uuid));

    // Reload the groups in general.
    dispatch(loadGroups({ force: true, stealth: true }));
  };
}

export function selectAdminGroupUuid(groupUuid) {
  return async dispatch => {
    dispatch(groupsSelectUuid(groupUuid));
    // After a group is selected, the users should be loaded based on the selected group.
    dispatch(loadAdminUsers({ reset: true }));
  };
}

const name = 'adminGroups';

export const adminGroupsSelector = createSelector(
  state => state[name],
  adminGroups => ({
    ...adminGroups,
    selectedGroup: adminGroups.data.find(({ uuid }) => uuid === adminGroups.selectedUuid),
  })
);

const adminGroupsReducer = createSlice({
  name,
  initialState,
  reducers: {
    groupsFetching: state => ({ ...state, error: null, waiting: true }),
    groupsSuccess: (state, { payload }) => ({ ...state, data: payload, waiting: false }),
    groupsFail: (state, { payload }) => ({ ...state, error: payload, waiting: false }),
    groupsSelectUuid: (state, { payload }) => ({ ...state, selectedUuid: payload }),
    reset: () => initialState,
    adminGroupDelete: (state, { payload }) => {
      state.data.splice(
        state.data.findIndex(({ uuid }) => uuid === payload),
        1
      );
    },
  },
});

const {
  groupsFetching,
  groupsSuccess,
  groupsFail,
  groupsSelectUuid,
  reset,
  adminGroupDelete,
} = adminGroupsReducer.actions;

export default adminGroupsReducer.reducer;
