import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { identity } from 'lodash-es';
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router';
import { PATH_ANALYTICS } from '../../../router/paths';
import { fieldFormValuesSelector, selectFieldsByUuid } from '../../../store/reducers/fieldsReducer';
import { useReportFilters } from '../../../utils/hooks/useReportFilters';
import {
  channelUuid,
  industryUuid,
  quarterUuid,
  reportTypeUuid,
  sentimentUuid,
  subSegmentUuid,
  tickerUuid,
  yearUuid,
} from '../../../values/apiFields';
import FilterField from '../../FilterField/FilterField';
import UnstyledButton from '../../UI/Button/Button';
import Spinner from '../../UI/Spinner';
import CustomerAndTeamSidebarFilters from './CustomerAndTeamSidebarFilters/CustomerAndTeamSidebarFilters';

export const sidebarSizes = {
  padding: '1rem',
  width: '12.5rem',
};

const StyledSidebar = styled.aside(
  ({ theme }) => css`
    padding: ${sidebarSizes.padding};
    width: ${sidebarSizes.width};
    background-color: ${theme.sideBackground};
    display: flex;
    flex-direction: column;
    align-items: stretch;
    box-sizing: content-box;
  `
);

const Form = styled.div``;
Form.defaultProps = {
  as: 'form',
};

const Button = styled(UnstyledButton)`
  border-radius: 0;
  margin-bottom: 0.5rem;
  height: 2rem;
`;

const fieldOrder = [
  industryUuid,
  subSegmentUuid,
  channelUuid,
  tickerUuid,
  sentimentUuid,
  yearUuid,
  quarterUuid,
  reportTypeUuid,
];

function Sidebar() {
  const { waiting } = useSelector(fieldFormValuesSelector);
  const fieldsByUuid = useSelector(selectFieldsByUuid);

  const { filters, onChange, applyFilters, clearFilters } = useReportFilters();

  return (
    <StyledSidebar className="layoutSidebar">
      <Form data-testid="sidebar-filters" onSubmit={applyFilters}>
        <Switch>
          <Route
            path={PATH_ANALYTICS}
            render={props => (
              <CustomerAndTeamSidebarFilters {...props} onChange={onChange} filters={filters} />
            )}
            exact
          />
        </Switch>

        {waiting ? (
          <Spinner style={{ minHeight: '10rem' }} />
        ) : (
          fieldOrder
            .map(fieldUuid => fieldsByUuid[fieldUuid])
            .filter(identity)
            .map(field => (
              <FilterField
                key={field.uuid}
                data-placeholder={field.name}
                onChangeSimple={values => onChange(field.uuid, values)}
                value={filters[field.uuid]}
                {...field}
              />
            ))
        )}
        <Button type="button" color="gray" onClick={clearFilters} style={{ width: '100%' }}>
          Clear Filters
        </Button>
        <Button
          type="submit"
          color="blue"
          data-testid="apply-filters-button"
          style={{ width: '100%' }}
        >
          Apply Filters
        </Button>
      </Form>
    </StyledSidebar>
  );
}

export default Sidebar;
