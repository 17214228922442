import api, { fieldOptionsUri } from '../../api';
import { normalizeError } from '../errorHandling';
import { createAdminEditSlice } from '../sliceCreators/adminEditSliceCreator';
import { loadFields } from './fieldsReducer';
import { tickerUuid } from '../../values/apiFields';
import { partition } from 'lodash-es';

async function apiSaveFieldOption(uuid, data) {
  let response;
  if (uuid) {
    response = await api.put(`${fieldOptionsUri}/${uuid}`, data);
  } else {
    response = await api.post(fieldOptionsUri, data);
  }

  return response.data.data;
}

export function saveFieldOption() {
  return async (dispatch, getState) => {
    dispatch(waiting());
    try {
      const adminFieldOptionEdit = adminFieldOptionEditSelector(getState());
      const { uuid, option, expiresAt, changes, ...data } = adminFieldOptionEdit.data;
      if (uuid) {
        let [newChanges, [expiryChange]] = partition(changes ?? [], v => v.name !== null);

        if (expiresAt) {
          expiryChange = expiryChange ? { ...expiryChange } : { name: null };
          expiryChange.startsAt = expiresAt;
          newChanges.push(expiryChange);
        }

        await api.put(`${fieldOptionsUri}/${uuid}/changes`, { changes: newChanges });
        await dispatch(loadFields());
      } else {
        data.fieldUuid = tickerUuid;
        data.value = option;
        await apiSaveFieldOption(uuid, data);
      }
      dispatch(success());

      // Trigger reloading fields, but don't wait for it.
      dispatch(loadFields({ force: true, stealth: true }));
    } catch (error) {
      dispatch(fail(normalizeError(error)));
      throw error;
    }
  };
}

export function loadAdminEditFieldOption(uuid) {
  return async dispatch => {
    dispatch(clear());
    if (!uuid) {
      // Creating a new fieldOption. No need to load any data.
      return;
    }
    const data = (await api.get(`${fieldOptionsUri}/${uuid}`)).data.data;
    if (data.changes) {
      const expiryChange = data.changes.find(v => v.name === null);
      if (expiryChange) {
        data.expiresAt = expiryChange.startsAt;
      }
    }

    dispatch(preload({ ...data, value: data.option }));
  };
}

export const adminFieldOptionEditSelector = state => state.adminFieldOptionEdit;

const propertyWhitelist = ['uuid', 'option', 'fieldUuid', 'changes', 'expiresAt'];

const adminFieldOptionEditSlice = createAdminEditSlice('adminFieldOptionEdit', propertyWhitelist);

const { waiting, fail, success, clear, setField, preload } = adminFieldOptionEditSlice.actions;

export const fieldOptionEditEditSetField = setField;
export const fieldOptionEditEditClear = clear;

export default adminFieldOptionEditSlice.reducer;
