import { createSlice } from '@reduxjs/toolkit';
import { identity, pick, set, unset } from 'lodash-es';

// The key under which dynamic UUID fields appear in the report's data.
export const DYNAMIC_UUID_FIELDS_PROPERTY = 'fields';
// This is actually a fake temporary property used so that we could work with the data as an object.
// In reality, KPIs are arrays according to the API.
export const KPI_VALUES_PROPERTY = 'kpi';
export const ATTRIBUTE_VALUES_PROPERTY = 'attributes';
export const EARNINGS_PROPERTY = 'earnings';
export const DEEPER_DIVE_PROPERTY = 'deeperDive';

/**
 * Creates a redux-start-kit slice meant for the control-panel's account/group/user edits.
 *
 * @param name Name of the slice.
 * @param propertyWhitelist Array of keys that are involved.
 * @param transformDataOrAdditionalDefaults {array|object|undefined} Function that transforms
 *  the data coming from the API; or an object containing defaults.
 *  Since the property whitelist only fills the properties with NULL values, you may want to pass an
 *  object to this parameter to specify that some properties should be an empty array or object
 *  by default. You may want to pass a function as the argument if the shape of a property is
 *  different when reading from to writing to it in the API.
 */
export function createAdminEditSlice(
  name,
  propertyWhitelist,
  transformDataOrAdditionalDefaults = {}
) {
  const data = {};
  // The initial data is everything in the property whitelist as null.
  for (const property of propertyWhitelist) {
    data[property] = null;
  }

  const transformData =
    typeof transformDataOrAdditionalDefaults === 'function'
      ? transformDataOrAdditionalDefaults
      : identity;
  const additionalDefaults =
    typeof transformDataOrAdditionalDefaults === 'object' ? transformDataOrAdditionalDefaults : {};

  const initialState = {
    waiting: false,
    error: null,
    data: { ...transformData(data), ...additionalDefaults },
  };

  return createSlice({
    name,
    initialState,
    reducers: {
      waiting: state => ({ ...state, error: null, waiting: true }),
      fail: (state, { payload }) => ({ ...state, error: payload, waiting: false }),
      success: state => ({ ...state, waiting: false }),
      clear: () => initialState,
      setField: (state, { payload: [property, value] }) => {
        // Object path access.
        if (Array.isArray(property)) {
          if (value.length) {
            set(state.data, property, value);
          } else {
            unset(state.data, property);
          }
          return;
        }
        // Direct access.
        state.data[property] = value;
      },
      preload: (state, { payload }) => {
        state.data = { ...initialState.data, ...pick(transformData(payload), propertyWhitelist) };
      },
    },
  });
}
