import { intersection, isEqual } from 'lodash-es';
import { func, object } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { accountOptionsSelector } from '../../../../store/reducers/accountsReducer';
import { fetchGroups } from '../../../../store/reducers/groupsReducer';
import FilterField from '../../../FilterField/FilterField';

function CustomerAndTeamSidebarFilters({ onChange, filters }) {
  const accountOptions = useSelector(accountOptionsSelector);
  const [groupOptions, setGroupOptions] = useState([]);
  const selectedGroupUuids = useMemo(() => filters.groupUuid || [], [filters.groupUuid]);

  const changeGroups = useCallback(
    groups => {
      setGroupOptions(groups);
      const groupUuids = groups.map(({ value }) => value);
      // Make sure to clear those selected groups that are not valid anymore.
      const selectedGroupUuidsToKeep = intersection(selectedGroupUuids, groupUuids);
      if (!isEqual(selectedGroupUuidsToKeep, selectedGroupUuids)) {
        onChange('groupUuid', selectedGroupUuidsToKeep);
      }
    },
    [onChange, selectedGroupUuids]
  );

  // Grab the list of groups depending on the accounts.
  useEffect(() => {
    (async () => {
      if (!(filters.accountUuid || []).length) {
        changeGroups([]);
        return;
      }
      const _groups = await fetchGroups(filters.accountUuid);
      changeGroups(_groups.map(({ uuid, name }) => ({ value: uuid, label: name })));
    })();
  }, [accountOptions, changeGroups, filters.accountUuid]);

  return (
    <div>
      <FilterField
        name="Customers"
        options={accountOptions}
        value={filters.accountUuid}
        onChangeSimple={values => onChange('accountUuid', values)}
      />
      <FilterField
        name="Teams"
        options={groupOptions}
        value={filters.groupUuid}
        onChangeSimple={values => onChange('groupUuid', values)}
      />
      <div style={{ height: '1rem' }} />
    </div>
  );
}

CustomerAndTeamSidebarFilters.propTypes = {
  onChange: func.isRequired,
  filters: object.isRequired,
};

export default CustomerAndTeamSidebarFilters;
