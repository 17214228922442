import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faCaretDown,
  faDownload,
  faFilePdf,
  faPencilAlt,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

library.add(faCaretDown);
library.add(faFilePdf);
library.add(faPencilAlt);
library.add(faDownload);
library.add(faTimes);

/**
 * Returns an array with the prefix and icon name that can be passed to <FontAwesome icon={...} />
 * @param icon
 * @returns {[string, string]}
 */
function getIconProp(icon) {
  return [icon.prefix, icon.iconName];
}

export const ICON_CARET_DOWN = getIconProp(faCaretDown);
export const ICON_DOWNLOAD = ['fas', 'download'];
export const ICON_TIMES = getIconProp(faTimes);
