import api, { attributesUri } from '../../api';
import { normalizeError } from '../errorHandling';
import { createAdminEditSlice } from '../sliceCreators/adminEditSliceCreator';
import { loadKPI } from './kpiReducer';

const name = 'adminKPIEdit';

async function apiSaveKPI(uuid, data) {
  let response;
  if (uuid) {
    response = await api.put(`${attributesUri}/${uuid}`, data);
  } else {
    response = await api.post(`${attributesUri}`, data);
  }

  return response.data.data;
}

export function saveKPI(channelUuid) {
  return async (dispatch, getState) => {
    dispatch(waiting());
    try {
      const adminKPIEdit = adminKPIEditSelector(getState());
      const { uuid, ...data } = adminKPIEdit.data;
      // Give names to the Predictability attributes.
      data.withChild = {
        name: 'Predictability Score',
        displayName: 'Predictability Score',
      };

      await apiSaveKPI(uuid, data);
      dispatch(success());

      // Trigger reloading control panel and global KPIs.
      dispatch(loadKPI(channelUuid, true));
    } catch (error) {
      dispatch(fail(normalizeError(error)));
      throw error;
    }
  };
}

export function loadAdminEditKPI(channelUuid, kpiId, preloadData = {}) {
  return async dispatch => {
    if (!kpiId) {
      dispatch(preload({ ...preloadData, fieldOptionUuid: channelUuid }));
      // Creating a new KPI. No need to load any data.
      return;
    }
    dispatch(clear());
    dispatch(
      preload({
        ...(await api.get(`${attributesUri}/${kpiId}`)).data.data,
        fieldOptionUuid: channelUuid,
      })
    );
  };
}

export const adminKPIEditSelector = state => state[name];

const propertyWhitelist = ['uuid', 'name', 'displayName', 'description', 'fieldOptionUuid'];

const adminKPIEditSlice = createAdminEditSlice(name, propertyWhitelist);

const { waiting, fail, success, clear, setField, preload } = adminKPIEditSlice.actions;

export const KPIEditEditSetField = setField;

const adminKPIEditReducer = adminKPIEditSlice.reducer;

export default adminKPIEditReducer;
