import { usersUri } from '../../api';
import { createFetchSlice, getInitialState } from '../sliceCreators/fetchSliceCreator';
import { createSelector } from 'reselect';
import { UserBare } from '../../types/entities';

const slice = createFetchSlice('users', {}, getInitialState<UserBare[]>());

export function loadUsers(email: string, options = {}) {
  return slice.loadUnlessTokenSame({
    uri: `${usersUri}?filter[email]=${email}`,
    ...options,
  });
}

const usersReducer = slice.reducer;
const usersSelector = slice.selector;
const usersReset = slice.actions.reset;
const usersSuccess = slice.actions.success;

export { usersSelector, usersReset, usersSuccess };

export const usersDataSelector = createSelector(usersSelector, v => v.data);

export default usersReducer;
