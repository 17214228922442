import { keyBy } from 'lodash-es';
import {
  PATH_ANALYTICS,
  PATH_BIOS,
  PATH_COMPANIES,
  PATH_CONTROL_PANEL,
  PATH_DASHBOARD,
  PATH_KPI_AND_TICKERS,
  PATH_ME_EDIT,
  PATH_PODCASTS,
  PATH_REPORT_CREATE,
  PATH_REPORT_EDIT,
  PATH_REPORT_SHOW,
  PATH_REPORTS,
  PATH_SCHEDULE,
} from './paths';
import { RouteProps } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { selectPermissions } from '../store/reducers/currentUserReducer';
import { ROLE_TENANT_OWNER } from '../values/roles';
import { lazy } from 'react';
import { scheduleURL } from '../utils/env';

const AnalyticsPage = lazy(() => import('../components/AnalyticsPage/AnalyticsPage'));
const BiosPage = lazy(() => import('../components/BiosPage'));
const ManagementPage = lazy(() => import('../components/ManagementPage'));
const CompaniesPage = lazy(() => import('../components/CompaniesPage/CompaniesPage'));
const ControlPanelPage = lazy(() => import('../components/ControlPanelPage/ControlPanelPage'));
const DashboardPage = lazy(() => import('../components/DashboardPage/DashboardPage'));
const MePage = lazy(() => import('../components/MePage/MePage'));
const PodcastsPage = lazy(() => import('../components/PodcastsPage'));
const ReportEditPage = lazy(() => import('../components/ReportEditPage/ReportEditPage'));
const ReportShowPage = lazy(() => import('../components/ReportShowPage/ReportShowPage'));
const ReportsPage = lazy(() => import('../components/ReportsPage/ReportsPage'));
const SchedulePage = lazy(() => import('../components/SchedulePage'));

type Route = RouteProps & { permission?: string; roles?: string[]; hide?: boolean };

const loggedInRoutes: Route[] = [
  { path: PATH_REPORT_CREATE, component: ReportEditPage },
  { path: PATH_REPORT_EDIT, component: ReportEditPage },
  { path: PATH_REPORT_SHOW, component: ReportShowPage },
  { path: PATH_REPORTS, component: ReportsPage },
  { path: PATH_DASHBOARD, component: DashboardPage },
  { path: PATH_ME_EDIT, component: MePage },
  { path: PATH_CONTROL_PANEL, component: ControlPanelPage, permission: 'accounts-create' },
  { path: PATH_ANALYTICS, component: AnalyticsPage, permission: 'readership-statistics-read' },
  { path: PATH_KPI_AND_TICKERS, component: ManagementPage, permission: 'attributes-read' },
  { path: PATH_COMPANIES, component: CompaniesPage, roles: [] },
  { path: PATH_BIOS, component: BiosPage, roles: ['tne_user', 'tne_admin'] },
  { path: PATH_PODCASTS, component: PodcastsPage },
];

if (scheduleURL) {
  loggedInRoutes.push({ path: PATH_SCHEDULE, component: SchedulePage });
}

export const loggedInRoutesByPath = keyBy(loggedInRoutes, 'path');

// Mark all paths as exact.
loggedInRoutes.forEach(route => {
  route.exact = route.path === '/';
});

export function useAvailableRoutes() {
  const currentUserData = useSelector((state: RootState) => state.currentUser.data);

  const permissions = useSelector(selectPermissions);

  if (!currentUserData?.role) {
    return [];
  }

  if (currentUserData.role === ROLE_TENANT_OWNER) {
    return loggedInRoutes;
  }

  return loggedInRoutes.filter(
    ({ permission, roles }) =>
      (!permission || permissions.includes(permission)) &&
      (!roles || roles.includes(currentUserData.role))
  );
}

export { loggedInRoutes };
