import styled from '@emotion/styled';
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router';
import { generatePath, Redirect } from 'react-router-dom';
import { useAvailableRoutes } from '../../router/routes';
import { ErrorBoundary } from '../../store/errorHandling';
import { loadAccounts } from '../../store/reducers/accountsReducer';
import { loadCurrentUser } from '../../store/reducers/currentUserReducer';
import { loadFields } from '../../store/reducers/fieldsReducer';
import { loadGroups } from '../../store/reducers/groupsReducer';
import { reportException } from '../../utils/errors';
import { useAllowedNavItems } from '../../utils/nav';
import ErrorPage from '../ErrorPage/ErrorPage';
import Flex from '../UI/Flex/Flex';
import FullPageSpinner from '../UI/FullPageSpinner/FullPageSpinner';
import Spinner from '../UI/Spinner';
import Header from './Header/Header';
import Navbar from './Navbar/Navbar';
import Sidebar from './Sidebar/Sidebar';

const StyledLayout = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Main = styled.main`
  overflow-y: scroll;
  flex: 1;
`;

function Layout() {
  const { currentUser } = useSelector(state => ({
    token: state.auth.token,
    currentUser: state.currentUser,
  }));
  const dispatch = useDispatch();
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        await Promise.all([
          // Load user data with their permissions.
          dispatch(loadCurrentUser()),
          // Load fields in advance.
          dispatch(loadFields()),
          dispatch(loadGroups()),
          dispatch(loadAccounts()),
        ]);
      } catch (e) {
        setError(e);
      }
    })();
  }, [dispatch]);

  const [mainWaiting, setMainWaiting] = useState(false);
  const setMainWaitingIfDifferent = useCallback(
    newMainWaiting => {
      if (newMainWaiting !== mainWaiting || typeof newMainWaiting === 'function') {
        setMainWaiting(newMainWaiting);
      }
    },
    [mainWaiting]
  );

  const allowedLoggedInRoutes = useAvailableRoutes();
  const allowedNavItems = useAllowedNavItems();

  if (error) {
    throw error;
  }

  if (currentUser.waiting || !currentUser.data || !allowedNavItems[0]) {
    return (
      <Flex fullHeight key="waiting">
        <Spinner />
      </Flex>
    );
  }

  return (
    <StyledLayout>
      <Header />
      <div style={{ flex: 1, display: 'flex', overflowY: 'hidden', overflowX: 'auto' }}>
        <Sidebar />
        <Flex style={{ flex: 1 }} fullHeight column stretch>
          <Navbar />
          <Main
            className="layoutMain"
            style={{ position: 'relative', ...(mainWaiting ? { overflow: 'hidden' } : {}) }}
          >
            <ErrorBoundary
              onError={error => {
                const chunkFailedMessage = /Loading chunk \d+ failed/;
                if (error?.message && chunkFailedMessage.test(error.message)) {
                  // The user probably had a tab open before a deploy, and the code-splitting encountered an outdated chunk
                  // filename hash. So just reload the page, and it should be fine. Solution #3 from:
                  // https://mitchgavan.com/code-splitting-react-safely/

                  window.location.reload();
                  return null;
                }
                reportException(error);
                return <ErrorPage error={error} />;
              }}
            >
              <Suspense fallback={<FullPageSpinner />}>
                <Switch>
                  {allowedLoggedInRoutes.map(({ path, component: Component, exact }) => (
                    <Route
                      key={path}
                      path={path}
                      render={({ match }) => (
                        <Component match={match} setMainWaiting={setMainWaitingIfDifferent} />
                      )}
                      exact={exact}
                    />
                  ))}
                  <Redirect path="*" exact={true} to={generatePath(allowedNavItems[0].to ?? '/')} />
                </Switch>
              </Suspense>
            </ErrorBoundary>
            {mainWaiting && <FullPageSpinner />}
          </Main>
        </Flex>
      </div>
    </StyledLayout>
  );
}

export default Layout;
