import { createSelector, createSlice } from '@reduxjs/toolkit';
import api from '../../api';
import { normalizeError } from '../errorHandling';
import { selectCurrentUserData } from './currentUserReducer';

async function updateMe(data) {
  const response = await api.put('/v1/me', data);
  return response.data.data;
}

export function changePassword() {
  return async (dispatch, getState) => {
    dispatch(waiting());
    try {
      const state = getState();
      const existingUserData = selectCurrentUserData(state);
      const { old_password, password, password_confirmation } = selectCurrentUserEditData(state);
      await updateMe({
        ...{
          name: existingUserData.name,
        },
        old_password,
        password,
        password_confirmation,
      });
    } catch (error) {
      dispatch(fail(normalizeError(error)));
      throw error;
    }
  };
}

const initialState = {
  waiting: false,
  error: null,
  data: {
    old_password: '',
    password: '',
    password_confirmation: '',
  },
};

const selectCurrentUserEdit = state => state.currentUserEdit;
export const selectCurrentUserEditData = createSelector(
  selectCurrentUserEdit,
  currentUserEdit => currentUserEdit.data
);
export const selectCurrentUserEditFieldErrors = createSelector(
  selectCurrentUserEdit,
  currentUserEdit => (currentUserEdit.error && currentUserEdit.error.data) || {}
);

const currentUserEditSlice = createSlice({
  name: 'currentUserEdit',
  initialState,
  reducers: {
    waiting: state => ({ ...state, error: null, waiting: true }),
    fail: (state, { payload }) => ({ ...state, error: payload, waiting: false }),
    clear: () => initialState,
    setField: (state, { payload: [property, value] }) => {
      state.data[property] = value;
    },
  },
});

const { waiting, fail, clear, setField } = currentUserEditSlice.actions;

export const currentUserEditSetField = setField;
export const currentUserEditClear = clear;

const currentUserEditReducer = currentUserEditSlice.reducer;

export default currentUserEditReducer;
