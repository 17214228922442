import { combineReducers } from '@reduxjs/toolkit';
import accountsReducer from './accountsReducer';
import adminAccountEditReducer from './adminAccountEditReducer';
import adminAccountsReducer from './adminAccountsReducer';
import adminFieldOptionEditReducer from './adminFieldOptionEditReducer';
import adminGroupEditReducer from './adminGroupEditReducer';
import adminGroupsReducer from './adminGroupsReducer';
import adminKPIEditReducer from './adminKPIEditReducer';
import adminUserEditReducer from './adminUserEditReducer';
import adminUsersReducer from './adminUsersReducer';
import analyticsReducer from './analyticsReducer';
import authReducer, { authLogout } from './authReducer';
import bioReducer from './bioReducer';
import biosReducer from './biosReducer';
import companiesReducer from './companiesReducer';
import companyNotesEditReducer from './companyNotesEditReducer';
import companyNotesReducer from './companyNotesReducer';
import controlPanelWidgetsReducer from './controlPanelWidgetsReducer';
import currentUserEditReducer from './currentUserEditReducer';
import currentUserReducer from './currentUserReducer';
import dataSourceUploadReducer from './dataSourceUploadReducer';
import fieldOptionReducer from './fieldOptionReducer';
import fieldsReducer from './fieldsReducer';
import groupsReducer from './groupsReducer';
import kpiReducer from './kpiReducer';
import podcastsReducer from './podcastsReducer';
import previousChannelReportReducer from './previousChannelReportReducer';
import reportCallsReducer from './reportCallsReducer';
import reportCompanyNotesPreviewReducer from './reportCompanyNotesPreviewReducer';
import reportDeleteReducer from './reportDeleteReducer';
import reportEditReducer from './reportEditReducer';
import reportKpisReducer from './reportKpisReducer';
import reportShowReducer from './reportShowReducer';
import reportsReducer from './reportsReducer';
import reportsStatisticsReducer from './reportsStatisticsReducer';
import rolesReducer from './rolesReducer';
import usersReducer from './usersReducer';

const appReducer = combineReducers({
  accounts: accountsReducer,
  adminAccountEdit: adminAccountEditReducer,
  adminAccounts: adminAccountsReducer,
  adminFieldOptionEdit: adminFieldOptionEditReducer,
  adminGroupEdit: adminGroupEditReducer,
  adminGroups: adminGroupsReducer,
  adminKPIEdit: adminKPIEditReducer,
  adminUserEdit: adminUserEditReducer,
  adminUsers: adminUsersReducer,
  analytics: analyticsReducer,
  auth: authReducer,
  bio: bioReducer,
  bios: biosReducer,
  companies: companiesReducer,
  companyNotesEdit: companyNotesEditReducer,
  companyNotes: companyNotesReducer,
  controlPanelWidgets: controlPanelWidgetsReducer,
  currentUser: currentUserReducer,
  currentUserEdit: currentUserEditReducer,
  dataSourceUpload: dataSourceUploadReducer,
  fields: fieldsReducer,
  fieldOption: fieldOptionReducer,
  groups: groupsReducer,
  kpi: kpiReducer,
  podcasts: podcastsReducer,
  previousChannelReport: previousChannelReportReducer,
  reportCalls: reportCallsReducer,
  reportCompanyNotesPreview: reportCompanyNotesPreviewReducer,
  reportDelete: reportDeleteReducer,
  reportEdit: reportEditReducer,
  reportKpis: reportKpisReducer,
  reportShow: reportShowReducer,
  reports: reportsReducer,
  reportsStatistics: reportsStatisticsReducer,
  roles: rolesReducer,
  users: usersReducer,
});

const rootReducer = (state, action) => {
  if (action.type === authLogout.type) {
    localStorage.clear();
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
