import api, { accountsUri } from '../../api';
import { normalizeError } from '../errorHandling';
import { loadAccounts } from './accountsReducer';
import { createAdminEditSlice } from '../sliceCreators/adminEditSliceCreator';
import { loadControlPanelWidgets } from './controlPanelWidgetsReducer';

async function apiSaveAccount(uuid, data) {
  let response;
  if (uuid) {
    response = await api.put(`${accountsUri}/${uuid}`, data);
  } else {
    response = await api.post(accountsUri, data);
  }

  return response.data.data;
}

export function saveAccount() {
  return async (dispatch, getState) => {
    dispatch(waiting());
    try {
      const adminAccountEdit = adminAccountEditSelector(getState());
      const { uuid, ...data } = adminAccountEdit.data;
      await apiSaveAccount(uuid, data);
      dispatch(success());

      // Trigger reloading accounts, but don't wait for it.
      dispatch(loadAccounts({ force: true, stealth: true }));
      dispatch(loadControlPanelWidgets());
    } catch (error) {
      dispatch(fail(normalizeError(error)));
      throw error;
    }
  };
}

export function loadAdminEditAccount(accountId) {
  return async dispatch => {
    dispatch(clear());
    if (!accountId) {
      // Creating a new account. No need to load any data.
      return;
    }
    dispatch(preload((await api.get(`${accountsUri}/${accountId}`)).data.data));
  };
}

export const adminAccountEditSelector = state => state.adminAccountEdit;

const propertyWhitelist = ['uuid', 'name', 'displayName'];

const adminAccountEditSlice = createAdminEditSlice('adminAccountEdit', propertyWhitelist);

const { waiting, fail, success, clear, setField, preload } = adminAccountEditSlice.actions;

export const accountEditEditSetField = setField;
export const accountEditEditClear = clear;

export default adminAccountEditSlice.reducer;
