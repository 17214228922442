import api, { groupsUri } from '../../api';
import { normalizeError } from '../errorHandling';
import { createAdminEditSlice } from '../sliceCreators/adminEditSliceCreator';
import { loadAdminGroups } from './adminGroupsReducer';
import { loadControlPanelWidgets } from './controlPanelWidgetsReducer';
import { loadGroups } from './groupsReducer';

async function apiSaveGroup(uuid, data) {
  let response;
  if (uuid) {
    response = await api.put(`${groupsUri}/${uuid}`, data);
  } else {
    response = await api.post(groupsUri, data);
  }

  return response.data.data;
}

export function saveGroup() {
  return async (dispatch, getState) => {
    dispatch(waiting());
    try {
      const adminGroupEdit = adminGroupEditSelector(getState());
      const { uuid, ...data } = adminGroupEdit.data;
      await apiSaveGroup(uuid, data);
      dispatch(success());

      // Trigger reloading control panel and global groups.
      dispatch(loadAdminGroups());
      dispatch(loadGroups({ force: true, stealth: true }));
      dispatch(loadControlPanelWidgets());
    } catch (error) {
      dispatch(fail(normalizeError(error)));
      throw error;
    }
  };
}

export function loadAdminEditGroup(groupId, preloadData = {}) {
  return async dispatch => {
    if (!groupId) {
      dispatch(preload(preloadData));
      // Creating a new group. No need to load any data.
      return;
    }
    dispatch(clear());
    dispatch(preload((await api.get(`${groupsUri}/${groupId}`)).data.data));
  };
}

export const adminGroupEditSelector = state => state.adminGroupEdit;

const propertyWhitelist = ['uuid', 'name', 'accountUuid', 'reportFilters', 'expiredAt'];

function transformData({ ...data }) {
  return {
    reportFilters: {},
    ...data,
  };
}

const adminGroupEditSlice = createAdminEditSlice(
  'adminGroupEdit',
  propertyWhitelist,
  transformData
);

const { waiting, fail, success, clear, setField, preload } = adminGroupEditSlice.actions;

export const groupEditEditSetField = setField;

export default adminGroupEditSlice.reducer;
