import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { array, func, oneOf, string } from 'prop-types';
import React, { useMemo, useRef } from 'react';
import { formatFieldOption } from '../../utils/fields';
import { apiFieldTypes } from '../../values/apiFields';
import Select from '../UI/Select/Select';

const Dropdown = styled(Select)`
  margin-bottom: 0.5rem;
`;

function FilterField({ name, options = [], value = [], onChangeSimple = () => {}, ...restProps }) {
  const el = useRef(null);
  const theme = useTheme();

  const styles = {
    control: provided => ({
      ...provided,
      borderRadius: 0,
      ...(theme.name === 'tne'
        ? {
            backgroundColor: 'rgba(255, 255, 255, 0)',
            borderColor: '#555',
            cursor: 'pointer',
          }
        : {}),
    }),
    indicatorSeparator: provided => ({
      ...provided,
      ...(theme.name === 'tne'
        ? {
            display: 'none',
          }
        : {}),
    }),
  };

  const displayOptions = useMemo(() => {
    return options.map(option => {
      return {
        ...option,
        label: formatFieldOption(option),
      };
    });
  }, [options]);

  return (
    <div ref={el} {...restProps}>
      <Dropdown
        styles={styles}
        placeholder={name}
        isMulti
        options={displayOptions}
        value={value}
        onChange={onChangeSimple}
      />
    </div>
  );
}

FilterField.propTypes = {
  name: string,
  type: oneOf(apiFieldTypes),
  options: array,
  uuid: string,
  onChangeSimple: func,
  value: array,
};

export default FilterField;
