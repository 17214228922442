import { stringify } from 'qs';
import api, { groupsUri } from '../../api';
import { createFetchSlice } from '../sliceCreators/fetchSliceCreator';

const slice = createFetchSlice('groups');

export async function fetchGroups(accountUuids) {
  const config = {};
  if (accountUuids) {
    config.paramsSerializer = params => stringify(params, { arrayFormat: 'brackets' });
    config.params = { filter: { account: accountUuids } };
  }

  const response = await api.get(groupsUri, config);
  return response.data.data;
}

export function loadGroups(options = {}) {
  return slice.loadUnlessTokenSame({ uri: groupsUri, ...options });
}

export const groupsDataSelector = state => state.groups.data || [];

const groupsReducer = slice.reducer;

export default groupsReducer;
