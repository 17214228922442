import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { defaultMemoize } from 'reselect';
import api, { reportsUri } from '../../api';
import { normalizeError } from '../errorHandling';

const waiting = createAction('reportDelete/waiting');
export const reportDeleteSuccess = createAction('reportDelete/success');
const fail = createAction('reportDelete/fail');
const reset = createAction('reportDelete/reset');

const initialState = {
  waitings: {},
  deleteds: {},
  errors: {},
};

async function _deleteReport(uuid) {
  await api.delete(`${reportsUri}/${uuid}`);
}

export function deleteReport(uuid) {
  return async dispatch => {
    dispatch(waiting(uuid));
    try {
      await _deleteReport(uuid);
      dispatch(reportDeleteSuccess(uuid));
    } catch (error) {
      dispatch(fail([uuid, normalizeError(error)]));
      throw error;
    }
  };
}

const selectReportDelete = state => state.reportDelete;

/**
 * Selects a function that returns a flat waiting and error state of a single UUID.
 */
export const selectReportDeleteByUuid = createSelector(
  selectReportDelete,
  ({ waitings, errors, deleteds }) => {
    return defaultMemoize(uuid => ({
      waiting: !!waitings[uuid],
      error: errors[uuid] || null,
      deleted: !!deleteds[uuid],
    }));
  }
);

const reportDeleteReducer = createReducer(initialState, {
  [waiting]: (state, { payload: uuid }) => {
    state.waitings[uuid] = true;
    state.errors[uuid] = null;
  },
  [reportDeleteSuccess]: (state, { payload: uuid }) => {
    state.waitings[uuid] = false;
    state.errors[uuid] = null;
    state.deleteds[uuid] = true;
  },
  [fail]: (state, { payload: [uuid, error] }) => {
    state.waitings[uuid] = false;
    state.errors[uuid] = error;
  },
  [reset]: () => initialState,
});

export default reportDeleteReducer;
