const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');

export const isIE = msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./);

// https://www.w3schools.com/js/js_cookies.asp
export function getCookie(cname) {
  var name = cname + '=';
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}
