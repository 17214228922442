import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { PATH_ANALYTICS, PATH_ME_EDIT } from '../../../router/paths';
import { logout } from '../../../store/reducers/authReducer';
import { selectCurrentUserData } from '../../../store/reducers/currentUserReducer';
import { headerHeight } from '../../../styles/variables';
import { isRoleTne } from '../../../utils/acl';
import { useReportFilters } from '../../../utils/hooks/useReportFilters';
import Flex from '../../UI/Flex/Flex';
import Input from '../../UI/Input/Input';
import { sidebarSizes } from '../Sidebar/Sidebar';

const padding = '1rem';
const StyledHeader = styled(Flex)(
  ({ theme }) => css`
    height: ${headerHeight};
    background-color: ${theme.topBackground};
    padding: ${padding};
    align-items: center;
    color: white;
  `
);
StyledHeader.defaultProps = {
  as: 'header',
};

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: underline;
  }
`;

const Header = ({ logout }) => {
  const location = useLocation();
  const isOnAnalyticsPage = useMemo(() => {
    return matchPath(location.pathname, PATH_ANALYTICS);
  }, [location.pathname]);
  const currentUserData = useSelector(selectCurrentUserData);
  const canShowElasticSearch =
    import.meta.env.VITE_FEATURE_ELASTICSEARCH &&
    !isOnAnalyticsPage &&
    !isRoleTne(currentUserData.role);
  const { filters, onChange, applyFilters } = useReportFilters();
  const theme = useTheme();

  return (
    <StyledHeader className="layoutHeader">
      <Link
        to="/"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: sidebarSizes.width,
          paddingRight: sidebarSizes.padding,
          boxSizing: 'content-box',
        }}
      >
        {theme.loggedInTopLeftLogo}
      </Link>

      {canShowElasticSearch && (
        <form
          name="elasticsearch"
          onSubmit={applyFilters}
          style={{ paddingLeft: sidebarSizes.padding }}
        >
          <div style={{ position: 'relative', height: '100%' }}>
            <Input
              placeholder="Search..."
              noFloatingPlaceholder
              value={filters['search'] ?? ''}
              onChange={evt => onChange('search', evt.currentTarget.value)}
              style={{ width: '40rem' }}
              forSidebar
            />
            <div
              style={{
                position: 'absolute',
                height: '100%',
                top: 0,
                right: '0.6rem',
                pointerEvents: 'none',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <FontAwesomeIcon icon={faSearch} color="black" />
            </div>
          </div>
        </form>
      )}

      <div style={{ flex: 1 }} />
      <div className="right">
        <StyledLink
          to={PATH_ME_EDIT}
          style={{ marginRight: '1em' }}
          data-testid="change-password-link"
        >
          Change Password
        </StyledLink>
        <button onClick={logout} data-testid="logout-button">
          Logout
        </button>
      </div>
    </StyledHeader>
  );
};

Header.propTypes = {
  logout: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ logout }, dispatch);
}

export default connect(null, mapDispatchToProps)(Header);
