import { createAction, createReducer } from '@reduxjs/toolkit';
import api from '../../api';
import { clearFromStorage, saveToStorage } from '../../utils/remember';
import { tenantUuid } from '../../values/apiFields';
import { handleError, reportError } from '../errorHandling';

const authStart = createAction('auth/start');
export const authSuccess = createAction('auth/success');
const authFail = createAction('auth/fail');
export const authLogout = createAction('auth/logout');

export const logout = () => {
  return dispatch => {
    clearFromStorage('token');
    dispatch(authLogout());
  };
};

export const auth = authAxiosPromise => {
  return dispatch => {
    dispatch(authStart());

    authAxiosPromise
      .then(response => {
        authSuccessReponse(response.data)(dispatch);
      })
      .catch(error => {
        clearFromStorage('token');
        const normalizedError = handleError(error);

        if (error.response === undefined) {
          return dispatch(authFail(normalizedError.message));
        }
        if (error.response.status === 403) {
          return dispatch(authFail("You don't have the permission to access this site"));
        }
        if (
          error.response.status === 401 ||
          (error.response.status === 422 && error.response.data.errors.email)
        ) {
          const message =
            error.response.status === 422
              ? Object.values(error.response.data.errors)
                  .flat()
                  .join('\n')
              : 'The user credentials were incorrect';
          return dispatch(authFail(message));
        }

        dispatch(authFail(normalizedError.message));
      });
  };
};

export const authPassword = (email, password) => {
  const postData = {
    email: email,
    password: password,
    tenant: tenantUuid,
  };

  return auth(api.post('/v1/login', postData));
};

export const authGoogle = idToken => {
  const postData = {
    idToken,
    tenant: tenantUuid,
  };

  return auth(api.post('/v1/google-login', postData));
};

export function saveUserIdToStorageByToken(token) {
  try {
    const jwt = JSON.parse(atob(token.split('.')[1]));
    const userId = jwt.sub;
    window.userId = userId;
    saveToStorage('userId', userId, true);
  } catch (e) {
    reportError(e);
  }
}

export function authSuccessReponse(responseData) {
  return dispatch => {
    const token = responseData.access_token;
    if (token !== undefined) {
      saveUserIdToStorageByToken(token);
      saveToStorage('token', token, true);
      saveToStorage('refreshToken', responseData.refresh_token, true);
      api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      dispatch(authSuccess({ token }));
    } else {
      dispatch(authFail('An error has occurred. Please contact system administration'));
    }
  };
}

/**
 * Checks if there's a token set and if it's still valid. If not, a logout is dispatched.
 *
 * @return {Function}
 */
export function authCheck() {
  return (dispatch, getState) => {
    const dispatchLogout = () => dispatch(logout());
    if (!getState().auth.token) {
      // No token.
      dispatchLogout();
    }
    api.get('/v1/me').catch(dispatchLogout);
  };
}

const initialState = {
  token: '',
  error: null,
  loading: false,
};

const authReducer = createReducer(initialState, {
  [authStart]: state => ({ ...state, error: null, loading: true }),
  [authSuccess]: (state, { payload: { token } }) => {
    state.token = token;
    state.error = null;
    state.loading = false;
  },
  [authFail]: (state, { payload }) => ({ ...state, error: payload, loading: false }),
  [authLogout]: () => ({ ...initialState, token: '' }),
});

export default authReducer;
