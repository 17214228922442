import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.min.css';

function displayMessage(message, type, options = {}) {
  iziToast[type]({
    message,
    overlayColor: 'rgb(0, 0, 0)',
    ...options,
  });
}

export function displaySuccess(message) {
  displayMessage.call(this, message, 'success', {
    displayMode: 'replace', // This way the success messages don't stack up.
  });
}

export function displayError(message) {
  displayMessage.call(this, message, 'error');
}
