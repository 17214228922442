import styled from '@emotion/styled';
import { red } from '../../styles/variables';

const StyledFieldErrors = styled.div`
  color: ${red};
  line-height: 150%;
  margin-bottom: 0.5em;
`;

function FieldErrors({ errors, ...restProps }: Props) {
  const errorsArray = splitErrors(errors);

  return errorsArray.length ? (
    <StyledFieldErrors {...restProps}>
      {errorsArray.map(errorLine => (
        <div key={Math.random()}>{errorLine}</div>
      ))}
    </StyledFieldErrors>
  ) : null;
}

type ErrorsInput = string[] | string | null | undefined;

interface Props {
  errors: ErrorsInput;
}

/**
 * Changes an array/string/undefined/null errors array into an array, and makes sure newlines
 * get split into new array items.
 */
export function splitErrors(errors: ErrorsInput): string[] {
  const array = Array.isArray(errors) ? errors : errors != null ? [errors] : [];

  return array.map(error => error.split('\n')).flat();
}

export default FieldErrors;
