import { rolesUri } from '../../api';
import { createFetchSlice, getInitialState } from '../sliceCreators/fetchSliceCreator';
import { createSelector } from 'reselect';
import { Role } from '../../types/entities';

const slice = createFetchSlice('roles', {}, getInitialState<Role[]>());

export function loadRoles(accountUuid: string, options = {}) {
  return slice.load({
    uri: `${rolesUri}?accountUuid=${accountUuid}`,
    ...options,
  });
}

const rolesReducer = slice.reducer;
const rolesSelector = slice.selector;
const rolesReset = slice.actions.reset;
const rolesSuccess = slice.actions.success;

export { rolesSelector, rolesReset, rolesSuccess };

export const rolesDataSelector = createSelector(rolesSelector, v => v.data);

export default rolesReducer;
