import { useMemo } from 'react';
import { generatePath, matchPath, useLocation } from 'react-router';
import { navItems } from '../components/Layout/Navbar/Navbar';
import { keyBy } from 'lodash-es';
import { useAvailableRoutes } from '../router/routes';

/**
 * Gets the list of top nav bar items that the current user is allowed to see.
 */
export function useAllowedNavItems() {
  const location = useLocation();

  const availablePathsByKey = keyBy(useAvailableRoutes(), 'path');

  return useMemo(() => {
    return navItems
      .filter(({ onlyIfActive, to }) => {
        return (
          availablePathsByKey[to] &&
          (!onlyIfActive || matchPath(location.pathname, { path: to, exact: true }))
        );
      })
      .map(item => ({ ...item, to: generatePath(item.to) }));
  }, [availablePathsByKey, location.pathname]);
}
