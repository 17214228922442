import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { matchPath, Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { PATH_FORGOT_PASSWORD } from '../../router/paths';
import { loggedInRoutes } from '../../router/routes';
import { reportError } from '../../store/errorHandling';
import { authGoogle, authPassword, logout } from '../../store/reducers/authReducer';
import { displayError } from '../../utils/toast';
import LoggedOutLayout, {
  FailureContainer,
  FormInputsContainer,
  Input,
  InputContainer,
  LinksContainer,
  LoginButton,
} from '../LoggedOutLayout/LoggedOutLayout';
import Spinner from '../UI/Spinner';
import GoogleLogin from './GoogleLogin';

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: underline;
  }
`;

function Login({ isAuth, authPassword, authGoogle, logout, loading, error, location }) {
  const [state, _setState] = useState({
    email: '',
    password: '',
  });
  function setState(newState) {
    _setState({ ...state, ...newState });
  }
  const { email, password } = state;
  const theme = useTheme();
  const showLogoOnTopOfForm = theme.name === 'tne';

  if (isAuth) {
    let redirectLocation = (location.state || {}).from;
    if (
      redirectLocation &&
      !loggedInRoutes.some(route => matchPath(redirectLocation.pathname, route))
    ) {
      // Not a valid path to redirect to, so just redirect to the dashboard.
      redirectLocation = '/';
    }
    return <Redirect to={redirectLocation || '/'} />;
  }

  function onSubmit(event) {
    event.preventDefault();

    if (error) {
      return logout();
    }

    authPassword(email, password);
  }

  return (
    <LoggedOutLayout onSubmit={onSubmit}>
      <>
        {showLogoOnTopOfForm && (
          <theme.BigLogoComponent style={{ margin: '3rem', fill: theme.primary }} />
        )}
        <FormInputsContainer>
          <div>
            {error ? (
              <FailureContainer>
                <p>{error}</p>
              </FailureContainer>
            ) : (
              <>
                <InputContainer>
                  <Input
                    type="text"
                    name="email"
                    placeholder="Email"
                    data-testid="login-email"
                    value={email}
                    onChange={evt => setState({ email: evt.target.value })}
                  />
                </InputContainer>
                <InputContainer>
                  <Input
                    type="password"
                    name="password"
                    placeholder="Password"
                    data-testid="login-password"
                    value={password}
                    onChange={evt => setState({ password: evt.target.value })}
                  />
                </InputContainer>
                <LinksContainer>
                  <div style={{ flex: 1 }} />
                  <StyledLink to={PATH_FORGOT_PASSWORD}>Forgot Password</StyledLink>
                </LinksContainer>
              </>
            )}
          </div>
        </FormInputsContainer>
        <LoginButton
          small
          color="blue"
          type="submit"
          data-testid="login-submit-button"
          disabled={loading}
        >
          {loading ? <Spinner small color="white" /> : error ? 'Try again' : 'Login'}
        </LoginButton>

        <div style={{ height: '3rem' }} />
        <div style={{ visibility: loading || error ? 'hidden' : undefined }}>
          <GoogleLogin
            onSuccess={response => {
              authGoogle(response.tokenId);
            }}
            onFailure={error => {
              if (error?.error === 'popup_closed_by_user') {
                // This is common. Do not report.
                return;
              }
              if (!(error instanceof Error)) {
                error = new Error(JSON.stringify(error));
              }
              reportError(error);
              displayError('Failed to log in with Google.');
            }}
          />
        </div>
      </>
    </LoggedOutLayout>
  );
}

Login.propTypes = {
  isAuth: PropTypes.bool,
  authPassword: PropTypes.func,
  authGoogle: PropTypes.func,
  logout: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.string,
  location: PropTypes.object,
};

function mapStateToProps({ auth }) {
  return {
    isAuth: !!auth.token,
    loading: auth.loading,
    error: auth.error,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ authPassword, authGoogle, logout }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
