import { configureStore } from '@reduxjs/toolkit';
import { getFromStorage } from '../utils/remember';
import listeners from './listeners';
import rootReducer from './reducers';
import { authSuccess, saveUserIdToStorageByToken } from './reducers/authReducer';
import { useDispatch, useSelector } from 'react-redux';

const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector = <TSelected = unknown>(selector: (state: RootState) => TSelected) =>
  useSelector<RootState, TSelected>(selector);

let token;
if ((token = getFromStorage('token'))) {
  saveUserIdToStorageByToken(token);
  // Set initial token.
  store.dispatch((authSuccess as any)({ token }));
}

listeners(store);

export default store;
