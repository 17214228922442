import { captureException } from '@sentry/browser';
import axios from 'axios';
import React, { useCallback, useState } from 'react';
import { displayError } from '../utils/toast';
import { addServerErrors } from '../utils/hooks/form';

const genericErrorMessage = 'An unexpected error has occurred, contact your administrator';

export const VALIDATION_FAILURE = 'validation_failure';
export const FILE_TOO_LARGE = 'file_too_large';
export const NOT_FOUND = 'not_found';
export const REQUEST_CANCELLED = 'request_cancelled';
export const UNAUTHENTICATED = 'unauthenticated';

/**
 * Normalizes (ajax) errors.
 */
//eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
export function normalizeError<Form>(error) {
  if (error && error.normalizedError) {
    // Already normalized.
    return error;
  }

  const ret: any = { message: genericErrorMessage, normalizedError: true };
  let response, status, data;
  if (axios.isCancel(error)) {
    ret.type = REQUEST_CANCELLED;
    ret.message = error.message;
    return ret;
  }

  if (error.message && loadingChunkFailedRegexp.test(error.message)) {
    ret.message = "This web application's assets have changed. Please refresh the page.";
    return ret;
  }

  // No response or response status.
  if (typeof error !== 'object' || !(response = error.response) || !(status = response.status)) {
    ret.message = formatGeneralErrorMessage(error);
    return ret;
  }

  if (status === 413) {
    ret.type = FILE_TOO_LARGE;
    ret.message = 'The uploaded file was too large.';
  }

  if (!(data = response.data)) {
    ret.message = `${genericErrorMessage}: No response data.`;
    return ret;
  }

  if (data.message) {
    ret.message = data.message;
  }

  if (status === 401) {
    ret.type = UNAUTHENTICATED;
  }

  if (status === 404) {
    ret.type = NOT_FOUND;
  }

  if (status === 422) {
    ret.type = VALIDATION_FAILURE;
    ret.data = data.errors || {};
  }

  if (!ret.message) {
    ret.message(formatGeneralErrorMessage(error));
  }
  return ret;
}

function formatGeneralErrorMessage(error) {
  try {
    return error?.message
      ? `${genericErrorMessage}: ${error.message}`
      : `${genericErrorMessage}: ${JSON.stringify(error)}`;
  } catch (e) {
    return genericErrorMessage;
  }
}

export class ErrorBoundary extends React.Component<
  {
    onError?: (error: any) => {};
    children: React.ReactNode;
  },
  { error: any }
> {
  state = {} as any;

  static getDerivedStateFromError(error) {
    return { error };
  }

  render() {
    if (this.state.error && this.props.onError) {
      return this.props.onError(this.state.error);
    }
    return this.props.children;
  }
}

export function reportError(error) {
  //eslint-disable-next-line no-console
  console.error(error);
  captureException(error instanceof Error ? error : new Error(JSON.stringify(error)));
}

/**
 * A hook to throw async errors at render time.
 */
export function useErrorThrower() {
  const [error, setError] = useState();

  if (error) {
    throw error;
  }

  const throwErrorDuringRender = useCallback(error => setError(error), []);

  return { throwErrorDuringRender };
}

export const loadingChunkFailedRegexp = /Loading chunk \d+ failed|Failed to fetch dynamically imported module/;

/**
 * Handles an error and then returns it. This function should be used with most new ajax requests.
 *
 * An error message is displayed, is logged to Sentry (unless it's a common, expected error),
 * and validation errors are set if the react-hook-form `setError` function is provided.
 *
 * @param error {any}
 * @param options
 * @param options.customHandler A custom handler of the error. If provided and it returns TRUE, then
 *  the default error handling does not happnen.
 */
export function handleError<Form>(error: any, { setError, customHandler }: any = {}) {
  const normalizedError = normalizeError<Form>(error);

  if (customHandler) {
    if (customHandler(normalizedError, error)) {
      return normalizedError;
    }
  }

  displayError(normalizedError.message);
  if (normalizedError.type === VALIDATION_FAILURE && setError) {
    addServerErrors(normalizedError.data, setError);
    // Consider the validation error as handled and do not report it.
    return normalizedError;
  }
  if (normalizedError.type === NOT_FOUND) {
    // Don't report these kinds of errors.
    return normalizedError;
  }
  reportError(error);

  return normalizedError;
}
